import React, { createContext, ReactNode, useContext, useState } from 'react';
import ProjectCardDto from '../models/ProjectCardDTO';


interface SearchContextType {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  searchResults: ProjectCardDto[];
  setSearchResults: React.Dispatch<React.SetStateAction<ProjectCardDto[]>>;
}
  
const defaultValue: SearchContextType = {
  searchQuery: '',
  setSearchQuery: () => {},
  searchResults: [],
  setSearchResults: () => {},
};

const SearchContext = createContext<SearchContextType>(defaultValue);


interface ISearchProviderProps{
    children : ReactNode;
  }


export const SearchProvider: React.FC<ISearchProviderProps> = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<ProjectCardDto[]>([]);

  return (
    <SearchContext.Provider value={{ searchQuery, setSearchQuery, searchResults, setSearchResults }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);