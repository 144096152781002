import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import SideBar from "../sidebar/SideBar";
import Footer from "../footer/Footer";


const Layout = ({ children }: { children: React.ReactNode }) => {
    const location = useLocation();
    const showNavbar = !location.pathname.startsWith('/user');
  
    return (
      <>
        {showNavbar? 
          <>
            <Navbar>
              {children}
              <Footer/>
            </Navbar>
          </>
          :
          <>
            <SideBar>
              {children}
            </SideBar>
          </>
        }
        
      </>
    );
  };
  
export default Layout