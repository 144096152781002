import React from 'react';

import { IconType } from "react-icons";

import PortifolioCategory from "../../containers/CategoryView";

import DescriptionComponent from '../../containers/DescriptionComponent';
import Avatar from '../../photo/Avatar';


interface ListingInfoProps {
  creationDate: number;
  updateDate: string;
  description: string;
  profilePic: string | undefined;
  city: string;
  country: string;
  state: string;
  name: string;
  // eventType: string;
  musicStyle: string | string[];
  highlight?: {
    icon: IconType,
    label: string;
    style: string;
  } | undefined;
}
const MINIO_PATH = process.env.REACT_APP_MINIO_URL

const PortifolioInfo: React.FC<ListingInfoProps> = ({
  description,
  name,
  profilePic,
  musicStyle,
  highlight,
  city,
  country,
  state,
  creationDate,
  updateDate
}) => {


  const src = profilePic? MINIO_PATH + profilePic : '/images/placeholder.jpg'
  

  return ( 
    <div className="flex flex-col ">
      <div className='flex flex-col gap-8 pb-10'>
        <div className="relative flex flex-col md:flex-row  gap-4">
          <section className='self-center relative top-[-15px]'>
            <Avatar src={src} big />
          </section>
          <div className="flex flex-col items-center md:items-start md:justify-end gap-4">
            <span 
              className="
              text-2xl 
              font-semibold 
              flex 
              flex-row 
              text-lilas
            "
            translate="no"
            >{name}</span>
            <div className="
              flex-col
              flex 
              items-center
              md:items-start
              font-light
              text-sm
              gap-2
            ">
            <span translate="no" className="flex items-center gap-2">{musicStyle}</span>
            <span translate="no" className="flex items-center gap-2">{city || "Cidade"} | {state || "UF"} | {country || "BR"}</span>
            </div>
          </div>
        </div>
        <span className='text-sm self-center md:self-start text-blueGray'>Pefil criado em {creationDate} - ultima atualização em {updateDate}</span>
        <hr className='border-customBackground w-[96%] self-center ' />

        {highlight && (
        <>
          <PortifolioCategory
            png=''
            label={"Mais visitado do mês na sua região"}
            description={"O perfil do artista foi o que recebeu mais visitantes esse mês"} 
          />      
          <hr className='border-customBackground w-[96%] self-center'/>
        </>
      )}
              <DescriptionComponent 
          description={description}
        />
      </div>


      
    </div>
   );
}
 
export default PortifolioInfo;