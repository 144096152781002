import React from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RegisterModal from './components/modals/RegisterModal';
import ToasterProvider from './providers/ToasterProvider';
import LoginModal from './components/modals/LoginModal';
import PortifolioModal from './components/modals/PortifolioModal';
import SearchModal from './components/modals/SearchModal';
import Home from './pages/Home/Home';
import PortifolioPage from './pages/Portifolio/Portifolio';
import { AuthContext, AuthProvider, AuthConsumer } from './context/JWTAuthContext';
import Loader from './components/Loader';
import {CookiesProvider} from "react-cookie"
import MyPortifolios from './pages/Portifolio/MyPortifolios';
import { Authenticated } from './components/Auth/Authenticated';
import Layout from './components/containers/Layout';
import PortifolioEditing from './pages/Portifolio/PortifolioEditing';
import { SearchProvider } from './context/SearchContext';
import ActiveAccout from './pages/Account/ActiveAccout';
import RecoverPassword from './pages/Account/RecoverPassword';
import RecoverPasswordModal from './components/modals/RecoverPasswordModal';




function App() {


  return (

    <>
      <CookiesProvider>
        <AuthProvider>
          <SearchProvider>
            <AuthConsumer>
              {(auth) => !auth.isInitialized? (
                <Loader/>
              ):(
              <>
                <Router>
                    <Layout>
                      <Routes>
                        <Route path='/' element={<Home/>} />
                        <Route path='/account/active/:token' element={<ActiveAccout/>}/>
                        <Route path='/account/recoverpassword/:token' element={<RecoverPassword/>} />
                        <Route path='/:identifier' element={<PortifolioPage/>}/>
                        <Route path='/user/myprojects' element={
                          <Authenticated>
                           <MyPortifolios />
                          </Authenticated>
                        }/>
                        <Route path='/user/myprojects/:identifier' element={
                          <Authenticated>
                            <PortifolioEditing/>
                          </Authenticated>
                        }/>
                      </Routes>   
                    </Layout>
                <RecoverPasswordModal/>       
                <PortifolioModal/>
                <SearchModal/>    
                <ToasterProvider/>      
                <LoginModal/>
                <RegisterModal/>
                </Router>
              </>
              )   
              }
            </AuthConsumer>
          </SearchProvider>
        </AuthProvider>
      </CookiesProvider>
    </>
  );
}

export default App;
