import axiosInstance from "./axios";

const route = "/user";
//TODO POR TYPE NOS TREM
export class UserService {
  async getAllUser() {
    return await axiosInstance.get(route);
  }

  async activateAccount(token: string) {
    return await axiosInstance.get(route + `/accountVerification/${token}`);
  }

  async registerAccount(data: any) {
    return await axiosInstance.post(route, data);
  }

  async deleteUser(id: any) {
    return await axiosInstance.delete(route + `/${id}`);
  }

  async updateUser(data: any) {
    return await axiosInstance.put(route + `/${data.identifier}`, data);
  }

  async getUserByLogin() {
    return await axiosInstance.get(route + "/me");
  }

  async login(data: any) {
    return await axiosInstance.post(route + "/login", data);
  }

  async refreshLogin(data: any) {
    return await axiosInstance.post(route + "/refresh/token", data);
  }

  async logout(data: any) {
    return await axiosInstance.post(route + "/logout", data);
  }

  async requirePasswordRecover(email: string) {
    return await axiosInstance.get(route + `/recoverPasswordRequired/${email}`);
  }

  async changePassword(data: any) {
    return await axiosInstance.put(route + "/updatePassword", data);
  }
}
