import React from "react";

interface ICoverUploader {
    src: string;
}

const CoverUploader: React.FC<ICoverUploader> = ({ src }) => {
    return (
        <img
            alt="Cover"
            src={src}
            className="w-[250px] h-[200px] object-cover xs:w-[300px] xs:h-[250px] sm:w-full sm:h-auto sm:max-h-[620px] sm:min-h-[620px]"
        />
    );
};

export default CoverUploader;