import React from "react";

interface HeadingProps {
    title?: string;
    subtitle?: string;
    center?: boolean;
    subsubtitle?: string;

}

const Heading: React.FC<HeadingProps> = ({
    title,
    subtitle,
    center,
    subsubtitle
}) =>{
    return(
        <div className={
            `
            mb-6
            ${center? 'text-center': 'text-start'}
            `}>
            <div className="text-2xl font-bold">
                {title}
            </div>
            <div className="font-light mt-2">
                {subtitle}
            </div>
            <div className="font-light text-sm mt-1">
                {subsubtitle}
            </div>
        </div>
    )
}

export default Heading