import React from "react";

interface ISoundCloudIframe{
    url : string    
}


const SoundCloudIframe: React.FC<ISoundCloudIframe> = ({
    url
})=>{

    const encodedLink = encodeURIComponent(url);
    const embedUrl = `https://w.soundcloud.com/player/?url=${encodedLink}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&visual=true`;
    

    return (
        <iframe
            className="w-full h-64 md:h-96 rounded-lg"  
            allow="autoplay"
            src = {embedUrl}
        ></iframe>
      );

}
export default SoundCloudIframe