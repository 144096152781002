import React from "react";
import { RiCloseCircleFill } from 'react-icons/ri';
import { CiCirclePlus } from "react-icons/ci";
import SelectComponent from "./Selectors/SelectComponent";
import { Control, Controller, FieldValues } from "react-hook-form";
import SimpleInput from "./SimpleInput";

type TOptions = {
    value: string;
    label: string;
    png?: string;
}

interface ITextInput  {
    active: boolean
    onRemove: () => void;
    onActivate: () => void;
    label:string;
    placeholder:string;
    options: TOptions[];
    control: Control<FieldValues,any>;
    onChange: (index: number, field: string, value: string)=>void;
    index: number;
    fieldName: string;
    subNameValue:string;
    nameValue:string;
    placeholderInput:string;
}


const TextInput: React.FC<ITextInput> = ({
    fieldName,
    active,
    onActivate,
    onRemove,
    label,
    placeholder,
    options,
    control,
    subNameValue,
    onChange,
    index,
    nameValue,
    placeholderInput
}) =>{

    const stringToTOptions = (stringValue: string): TOptions => {
        return {
            value: stringValue,
            label: stringValue,
        };
    };

    return(

        <div className="flex flex-col gap-5 border-customBackground border-[1px] rounded-[6px] p-2 w-full">
        {active?  
            <>
                <div className="flex flex-col items-center gap-2 relative ">

                    <button
                        className="absolute top-0 right-0 flex items-center justify-center px-2 text-redError hover:text-customRed"
                        onClick={onRemove}
                      >
                        <RiCloseCircleFill size={12} />
                    </button>
                    <div
                        className="w-full"
                    >
                    <Controller
                        name = {`${fieldName}.${index}.item`}
                        control={control}
                        render={({field})=>(
                            <SimpleInput
                                placeholder={placeholderInput}
                                id={`${fieldName}.${index}.item`}
                                onChange={(e)=>{
                                    field.onChange(e);
                                    onChange(index,'item',e.target.value)}}
                                value={nameValue}
                                label={label}
                            />
                        )}
                    />
                   </div>
                    <div className="w-full">
                        <label>{placeholder}</label>
                        <Controller
                            name={`${fieldName}.${index}.content`}
                            control={control}
                            render={({field})=>(
                                <textarea
                                    onChange={(e)=>{
                                        field.onChange(e);
                                        onChange(index,'content',e.target.value)}}
                                    placeholder={placeholder}
                                    value={subNameValue}
                                    className={`
                                      peer
                                      w-full
                                      h-28  
                                      py-1.5
                                      px-2.5
                                      font-light
                                      bg-customCards
                                      border-2
                                      border-customCards
                                      focus:border-lilas
                                      leading-5
                                      rounded-md
                                      outline-none
                                      transition
                                      disabled:opacity-70
                                      disabled:cursor-not-allowed
                                    `}
                                />
                            )}
                        />
                        
                    </div>
                </div>
            </>
            :
            <>
                <section 
                onClick={onActivate}
                className="flex flex-col justify-center items-center text-lilasHover h-full">
                    <CiCirclePlus size={200} />
                </section>
            </>
        }
        </div>
    )


}

export default TextInput;