import React from "react";

interface IPorttifolioEditSection{
    title: string
    children: React.ReactNode
}

const PortifolioEditSection: React.FC<IPorttifolioEditSection> = ({
    title,
    children
}) =>{

    return(
        <div className="
            w-full
            flex 
            flex-col 
            gap-2
            border-b-[1px]
            border-customBackground
            pb-4
            mb-4
            "
        >
        
          <div className="text-xl ">{title}</div>
            {children}
        </div>
        

    )


}

export default PortifolioEditSection