import qs from "query-string";
import React, { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

interface CategoryBoxProps {
  genero: string;
  png: string;
  identifier: string;
  selected?: boolean;
}

const PATH = process.env.REACT_APP_MINIO_URL;

const CategoryBox: React.FC<CategoryBoxProps> = ({
  genero,
  png,
  identifier,
  selected,
}) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const handleClick = useCallback(() => {
    let currentQuery = {};

    if (params) {
      currentQuery = qs.parse(params.toString());
    }

    const updatedQuery: any = {
      ...currentQuery,
      musicStyle: genero,
    };

    if (params?.get("musicStyle") === genero) {
      delete updatedQuery.musicStyle;
    }

    const url = qs.stringifyUrl(
      {
        url: "/",
        query: updatedQuery,
      },
      { skipNull: true }
    );

    navigate(url);
  }, [genero, params]);

  return (
    <div
      onClick={handleClick}
      className={`
                flex
                flex-col
                items-center
                justify-center
                gap-2
                p-3
                hover:text-indigo
                transition
                cursor-pointer
                ${
                  selected
                    ? "border-b-lilas text-lilas border-b-2"
                    : "border-transparent"
                }
            `}
    >
      <img src={png} width={26} height={26} />
      <div translate="no" className="text-center text-xs md:text-sm">
        {genero}
      </div>
    </div>
  );
};

export default CategoryBox;
