import React,{useEffect} from 'react'
import qs from 'query-string';
import { useCallback, useMemo, useState } from "react";

import { musicStyles } from '../../utils/StaticData';

import useSearchModal from '../../hooks/useSearch';

import Modal from "./Modal";

import Heading from '../Heading';
import SimpleInput from '../inputs/SimpleInput';
import { useSearch } from '../../context/SearchContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';


enum STEPS {
  LOCATION = 0,
  DATE = 0,
  INFO = 0,
}


const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#212123',
    color: '#C9C9C9',
    borderColor: state.isFocused ? '#6638FE' : '#212123', 
    boxShadow: state.isFocused ? '0 0 0 1px #6638FE' : 'none', 
    '&:hover': {
      borderColor: state.isFocused ? '#6638FE'  : '#212123', 
    },

  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#212123',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#6638FE' : state.isFocused ? '#4E35CD' : '#212123',
    color: '#C9C9C9',
  }),
  singleValue: (provided : any) => ({
    ...provided,
    color: '#C9C9C9', 
  }),
  placeholder: (provided : any) => ({
    ...provided,
    color: '#C9C9C9',
    fontSize: '0.75rem', 
    paddingLeft: '0.5rem',
  }),
  input: (provided : any) => ({
    ...provided,
    color: '#C9C9C9', 
    paddingLeft: '0.5rem',
  }),
  indicatorSeparator: (provided : any) => ({
    display: 'none',
  }),
};


const SearchModal = () => {
  //const router = useRouter();
  const searchModal = useSearchModal();
  const {setSearchQuery} = useSearch();
  const [params] = useSearchParams();


  const [step, setStep] = useState(STEPS.LOCATION);
  const [name, setName] = useState('');
  const [musicStyle, setMusicStyle] = useState<TOptions>();
  const [state,setState] = useState('');
  const [city,setCity] = useState('');
  const navigate = useNavigate(); 


  // DEPRECIADOS
  // const [location, setLocation] = useState<CountrySelectValue>();
  // const [guestCount, setGuestCount] = useState(1);
  // const [roomCount, setRoomCount] = useState(1);
  // const [bathroomCount, setBathroomCount] = useState(1);
  // const [dateRange, setDateRange] = useState<Range>({
    // startDate: new Date(),
    // endDate: new Date(),
    // key: 'selection'
  // });

  //Testar novamente
  // const [latlong, setLatlong] = useState<number[]>([])


  // useEffect(()=>{
  //   getLocation()
  // },[])
  // function getLocation() {      
  //   navigator.geolocation.getCurrentPosition(location => {
  //     let latlng = [location.coords.latitude,location.coords.longitude]
  //     setLatlong(latlng);
  //   })
  // }


//   const Map = useMemo(() => dynamic(() => import('../Map'), { 
    // ssr: false 
//   }), [location]);

  const onBack = useCallback(() => {
    setStep((value) => value - 1);
  }, []);

  const onNext = useCallback(() => {
    setStep((value) => value + 1);
  }, []);

  // const onSubmit = useCallback(async () => {
    // if (step !== STEPS.INFO) {
      // return onNext();
    // }
// 
    // let currentQuery = {};
// 
    // if (params) {
      // currentQuery = qs.parse(params.toString())
    // }
// 
    // const updatedQuery: any = {
      // ...currentQuery,
      // locationValue: location?.value,
      // guestCount,
      // roomCount,
      // bathroomCount
    // };
// 
    // if (dateRange.startDate) {
      // updatedQuery.startDate = formatISO(dateRange.startDate);
    // }
// 
    // if (dateRange.endDate) {
      // updatedQuery.endDate = formatISO(dateRange.endDate);
    // }
// 
    // const url = qs.stringifyUrl({
      // url: '/',
      // query: updatedQuery,
    // }, { skipNull: true });
// 
    // setStep(STEPS.LOCATION);
    // searchModal.onClose();
  // }, 
  // [
    // step, 
    // searchModal, 
    // location, 
    //router, 
    // guestCount, 
    // roomCount,
    // dateRange,
    // onNext,
    // bathroomCount,
    //params
  // ]);
// 



  const handleSearch = () =>{
    let currentQuery = {};

    if (params) {
      currentQuery = qs.parse(params.toString())
    }

    let musics = musicStyle?.value
    
    const updatedQuery: any = {
      ...currentQuery,
      city,
      state,
      name,
      musicStyle: musics
    }

    if (params?.get('city') === city) {
      delete updatedQuery.city;
    }

    if (params?.get('state') === state) {
      delete updatedQuery.state;
    }

    if (params?.get('name') === name) {
      delete updatedQuery.name;
    }

    const url = qs.stringifyUrl({
      url: '/',
      query: updatedQuery
    }, { skipNull: true });



    searchModal.onClose()
    navigate(url);
  }

  useEffect(()=>{
    setSearchQuery(params.toString());
  },[params])

  const actionLabel = useMemo(() => {
    if (step === STEPS.INFO) {
      return 'Search'
    }

    return 'Next'
  }, [step]);

  const secondaryActionLabel = useMemo(() => {
    if (step === STEPS.LOCATION) {
      return undefined
    }

    return 'Back'
  }, [step]);


  // if (step === STEPS.DATE) {
    // bodyContent = (
      // <div className="flex flex-col gap-8">
        {/* <Heading */}
          // title="When do you plan to go?"
          // subtitle="Make sure everyone is free!"
        // />
        {/* <Calendar */}
          // onChange={(value) => setDateRange(value.selection)}
          // value={dateRange}
        // />
      {/* </div> */}
    // )
  // }

  
    const bodyContent = (
      <div className="flex flex-col gap-4">
        <Heading
          center
          title="Filtre por uma ou mais opções."
          subtitle='A escolha é sua'
        />

        <SimpleInput
          id='name'
          onChange={(e)=> setName(e.target.value)}
          placeholder='Digite o Nome'
          value={name}
          label='Nome'
        />

        <SimpleInput
          id='state'
          onChange={(e)=> setState(e.target.value)}
          placeholder='Digite o estado'
          value={state}
          label='Estado'
        />

        <SimpleInput
          id='city'
          onChange={(e)=> setCity(e.target.value)}
          placeholder='Digite a cidade'
          value={city}
          label='Cidade'
        />
        <label>Estilo Musical</label>
         <Select
            id={'musicStyle'}
            placeholder = {"Estilo musical"}
            styles={customStyles}
            options={musicStyles}

            value={musicStyle ? musicStyle : null}
            onChange={(value) => setMusicStyle(value as TOptions)}
            
          />

      </div>
    )

  return (
    <Modal
      isOpen={searchModal.isOpen}
      title="Filtro"
      actionLabel={"Buscar"}
      onSubmit={handleSearch}
      onClose={searchModal.onClose}
      body={bodyContent}
    />
  );
}

export default SearchModal;

type TOptions = {
  value: string;
  label: string;
  png: string;
}