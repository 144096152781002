import axiosInstance from "../services/axios";


export const setSession = (accessToken) => {

    if (accessToken) {
        axiosInstance.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${accessToken}`;
    } else {
        delete axiosInstance.defaults.headers.common["Authorization"]
    }
}

export const resetSession = () => {
    delete axiosInstance.defaults.headers.common["Authorization"]
}