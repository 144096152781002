import React from "react";


interface AvatarProps{
    src: string | null | undefined;
    big?: boolean  
}

const Avatar: React.FC<AvatarProps> = ({
    src,
    big,
}) =>{
    return(
      <img
        alt="Avatar"
        className={
          `rounded-full
          ${big && 'border-[8px] border-lilasHover'}
          `
        }
        style={{
          height: (214 || 'auto'),
          width: (214 || 'auto'),
          objectFit: 'cover',
          marginTop:'15px',
          marginRight: '15px' 
      }}
        src={src || "/images/avatar.png"}
      />
    )
}

export default Avatar