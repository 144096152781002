import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '../../Button';
import { RiCloseCircleFill } from 'react-icons/ri';
import GaleryUploader from './GaleryUploader';
import { FileService } from '../../../services/FileService';
import { toast } from 'react-hot-toast'; 
import { FaCheckCircle } from "react-icons/fa";

interface FileWithPreview {
  file: File;
  preview: string;
}

const path = process.env.REACT_APP_MINIO_URL


interface ImageUploaderProps {
  onUpload: (url: string []) => void;
  imagens: string[];
  maxFiles: number;
  pathAndName: string;
  children?: React.ReactNode;
  specification?: string;
  onSetPathValue: (value: any) => void;
}
// TODO URGENTE depreciar essa porra e começar de novo. Da um jeito de deixar isso melhor meu filho.......


const ImageUploader: React.FC<ImageUploaderProps> = ({ onUpload, maxFiles,children,specification,pathAndName,onSetPathValue,imagens }) => {
  const ismultiple = maxFiles === 1 ? false : true;
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [previews, setPreviews] = useState<string[]>([]);
  const fileService = new FileService();

  const maxFileSize = 5 * 1024 * 1024;
  
  useEffect(() => {
    setPreviews(imagens);
  }, [imagens]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (previews.length + acceptedFiles.length > maxFiles) {
      setError(`Você não pode dar upload em mais de ${maxFiles} arquivos.`);
      return;
    }

    const newFiles = acceptedFiles.map((file,index) => (file));

    if(!ismultiple) {handleUpload(newFiles)}
          
    const uniqueNewFiles = newFiles.filter(newFile =>
      !files.some(existingFile => existingFile.name === newFile.name)
    );

    const newPreviews =  uniqueNewFiles.map((file) => {
      return (URL.createObjectURL(file))
    }) 

    
    setFiles((currentFiles) => [...currentFiles, ...uniqueNewFiles]);
    setPreviews((currentPrevies)=> [...currentPrevies,...newPreviews]);
    onUpload([...previews, ...newPreviews]);
  }, [maxFiles, files, onUpload]);

  const { getRootProps, getInputProps,open } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg" : [".jpeg",".jpg"],
      "image/png" : [".png"],
    },
    maxFiles,
    multiple: ismultiple,
    noClick: true, 
    noKeyboard: true,
    validator: (file) => {
      if (file.size > maxFileSize) {
        toast.error("Imagem muito grande reveja as especificações")
        return {
          code: 'file-too-large',
          message: `Tamalho do arquivo excede 5MB.`,
        };
      }
      return null;
    },
  });

  const handleUpload = (files: File[]) => {
    if (files.length === 0) {
      onSetPathValue(previews);
      toast.success("Imagens atualizadas");
      return;
    } 

    const data = new FormData();
    const existingFileNames = previews.map(preview => {
        const parts = preview.split('/');
        const fileName = parts.shift();
        parts[parts.length-1] = (parts[parts.length-1].split('.').shift()) || '';
        const existingFiles = parts.join('/')
        return existingFiles
      });
    let counter = 1;
    let newFiles = ['']

    
    files.forEach((file, index) => {
      
      let pathName = pathAndName;
      let fileExtension = file.type.split('/').pop() || '';
      

      if(maxFiles!=1){
        let fileName = `${pathName}-${counter}`;

        while (existingFileNames.includes(fileName) || newFiles.includes(fileName) ) {
          counter++;
          fileName = `${pathName}-${counter}`;
        }
        pathName = fileName
      }

      newFiles.push(pathName)
      
      const fileName  = `${pathName}.${fileExtension}`

      
      data.append("files", file, fileName);
    });
  
    const filteredPreviews = previews.filter(preview => preview.includes(pathAndName));

    fileService.uploadFile(data)
    .then((response) => {
      toast.success("Imagem enviada");

      const updatedPreviews = [...filteredPreviews, ...response.data];
      
      
      onSetPathValue(updatedPreviews); 
    })
    .catch((error) => {
      toast.error("Erro ao subir imagem.");
    });
    
    
  };
  
  

  const handleRemoveFile = (index: number) => {
    const fileToRemove = files[index];
    setFiles((currentFiles) => {
      const updatedFiles = currentFiles.filter((_, i) => i !== index);
      return updatedFiles;
    });
  
    setPreviews((currentPreviews) => {
      const updatedPreviews = currentPreviews.filter((_, i) => i !== index);
      return updatedPreviews;
    });
  
    // Revoga a URL do objeto fora do setState para evitar problemas de sincronização
    setTimeout(() => {
      if (fileToRemove) {
        URL.revokeObjectURL(URL.createObjectURL(fileToRemove));
      }
    }, 0);
  };

  useEffect(()=>{
  },[setPreviews])


  return (
    <div className='h-auto w-full'>
      <div  {...getRootProps({ className: 'dropzone' })}>
        {(previews == null || (previews && previews.length < maxFiles))  && 
          <div className=' max-w-[300px] mb-4 flex flex-col gap-2'>
            <input {...getInputProps()} />
            {specification && <p className='text-xs text-orangeP'>{specification}</p>}
            <div className='flex flex-col gap-2'>
              <p>Adicione imagens</p>
              {maxFiles>1 &&
              <>
                <p className='text-xs text-orangeP'>A imagem deve manter a proporção 1280px de largura por 720px de altura e tamanho menor que 1MB</p>
                <p className='text-sm text-customRed'>É necessário clicar no botão "salvar imagens enviadas" para que elas sejam salvas*</p>
              </>
              }
              <Button label='Adicionar imagem' onClick={open}/>
            </div>
          </div>
        }
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      <div className={`h-full w-full pb-20 md:pb-0 ${children ? 'justify-center items-center flex flex-row' : 'grid grid-cols-1 md:grid-cols-4'  }  gap-4 relative`}>
        {children && previews?
        previews.map((file, index) => (
          
          <div  key={index} style={{ display: 'inline-block',position:'relative'}}>
            {children}

            <button
              className="absolute top-2 right-0 flex items-center justify-center px-2 text-redError hover:text-customRed"
              onClick={() => handleRemoveFile(index)}
            >
              <RiCloseCircleFill size={28} />
            </button>
          </div>
        ))
        :
        
        <>
        {(previews && previews.length > 0  && maxFiles>1)  &&
          <>{
            previews.map((file, index) => (
              <div  key={index} style={{ display: 'inline-block',position:'relative'}}>
                <GaleryUploader src={file.includes('blob')? file : path + file} onRemove={() => handleRemoveFile(index)}/>
              </div>
          ))
          }
            
          </>

        }
        {maxFiles>1 &&
            <button
                className="absolute bottom-2 right-0 flex items-center justify-center p-4 rounded-md bg-lilas hover:bg-lilasHover"
                onClick={() => handleUpload(files)}
              >
              <p className='text-lg font-bold'>Salvar imagens enviadas</p>
            </button>
            }
        </>  
        }
        
        
      </div>
    
      
      {/* {files.length >= maxFiles && <p>Você atingiu o número máximo de {maxFiles} arquivos.</p>} */}
    </div>
  );
};

export default ImageUploader;