import React from "react";
import { RiCloseCircleFill } from 'react-icons/ri';


interface IGaleryUploader{
    src: string;
    onRemove: ()=> void;
}

const GaleryUploader : React.FC<IGaleryUploader>   = ({
    src,
    onRemove
}) =>{
    return(
        <div className='relative inline-block'>
          <img
            alt="Galery"
            src={src}
            style={{height:'auto', width: 'auto', objectFit: 'cover', borderRadius:'6px' }}
          />
          <button
            className="absolute top-2 right-0 flex items-center justify-center px-2 text-redError hover:text-customRed"
            onClick={onRemove}
          >
            <RiCloseCircleFill size={28} />
          </button>
        </div>
        
    )



}

export default GaleryUploader