import React from "react";

interface IYoutubeIframe{
    youtubeUrl : string    
}


const YoutubeIframe: React.FC<IYoutubeIframe> = ({
    youtubeUrl
})=>{
   
    function convertToEmbedUrl(youtubeUrl:string) {
        let videoId = youtubeUrl.split('v=')[1];
        if(!videoId) {
            videoId = youtubeUrl.split('/')[youtubeUrl.split('/').length-1];
            return `https://www.youtube.com/embed/${videoId}`
        }
        const ampersandPosition = videoId.indexOf('&');
        if(ampersandPosition !== -1) {
            return `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}`;
        }
        return `https://www.youtube.com/embed/${videoId}`;
    }

    return (
        <iframe
            className="w-full h-64 md:h-96"
            src={convertToEmbedUrl(youtubeUrl)}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
      );

}
export default YoutubeIframe