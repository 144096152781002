import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Logo from "../navbar/Logo";
import ItemList from "./ItemList";
import { IoPersonOutline } from "react-icons/io5";
import MiniAvatar from "../navbar/MiniAvatar";
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";

interface ISideBar {
  children: React.ReactNode;
}

const SideBar: React.FC<ISideBar> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-row w-full w-max-full overflow-x-hidden">
      <motion.div
        className={`fixed inset-y-0 left-0 w-64 bg-customModal h-screen z-50 md:relative flex flex-col items-center md:w-64 md:translate-x-0 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        initial={{ x: "-100%" }}
        animate={{
          x: isOpen || window.innerWidth >= 768 ? 0 : "-100%",
        }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        {/* <div className="w-64 flex flex-col h-screen bg-customModal  items-center "> */}
        <div className="flex flex-col py-8 gap-4 w-4/5">
          <div
            className="
              flex
              w-full
              justify-center
              pb-4
            "
          >
            <Logo />
          </div>
          <div
            className="
              flex
              flex-row
              items-center
              gap-2
              "
          >
            <MiniAvatar src="" />
            Todos os projetos
          </div>
        </div>
        {/* <hr className="w-4/5" /> */}
        {/* <ul className="space-y-4 p-4 w-4/5"> */}
        {/* <ItemList icon="/sideBarIcons/home.png" label="Dashboard" path="user/dashboard"/> */}
        {/* </ul> */}
        {/* <hr className="w-4/5" /> */}
        {/* <ul className="space-y-4 p-4 w-4/5"> */}
        {/* <ItemList icon="/sideBarIcons/contato.png" label="Contatos" path="user/contact"/> */}
        {/* <ItemList icon="/sideBarIcons/prospecção.png" label="Prospecção" path="user/prospection"/> */}
        {/* <ItemList icon="/sideBarIcons/negociacoes.png" label="Negociações" path="user/negotiation"/> */}
        {/* <ItemList icon="/sideBarIcons/fechamento.png" label="Fechamentos" path="user/closings"/> */}
        {/* </ul> */}
        <hr className="w-4/5" />
        <ul className="space-y-4 p-4 w-4/5">
          {/* <ItemList icon="/sideBarIcons/logistica.png" label="Logísticas" path="user/logistic"/> */}
          {/* <ItemList icon="/sideBarIcons/financeiro.png" label="Financeiro" path="user/financial"/> */}
          <ItemList
            icon="/sideBarIcons/setting.png"
            label="Gerenciar Projetos"
            path="user/myprojects"
          />
        </ul>

        {/* </div > */}
      </motion.div>

      {/* Tela principal */}
      <div className="flex-1">
        <button
          className="md:hidden fixed top-4 left-4 z-50"
          onClick={toggleSidebar}
        >
          {isOpen ? <IoCloseOutline size={24} /> : <IoMenuOutline size={24} />}
        </button>
        {children}
      </div>
    </div>
  );
};

export default SideBar;
