import React from "react";
import axios from "axios";
import BigPicture from "../photo/CustomSizePicture";

import MusicList from "../MusicList";

interface PortifolioSectionProps{
    body: React.ReactNode;
    title: string;
    buttons?: React.ReactNode;
}

const PortifolioSection: React.FC<PortifolioSectionProps> = ({
    body,
    title,
    buttons
}) =>{


    return(
        <div className="col-span-8 flex flex-col self-center w-[96%] gap-8 py-2 ">
            <hr className='border-customBackground  '/>
            <div className="flex flex-row items-center justify-between">
              <div 
                className="
                  text-xl 
                  font-semibold 
                  flex 
                  flex-row 
                  items-center
                  gap-2
                "

              >
                <span>{title}</span>
              </div>
              {buttons}
            </div>
            <div>
                {body}
            </div>
        </div>
    )
}

export default PortifolioSection