
import { useEffect, useMemo, useState } from "react";
import EmptyState from "../../components/EmptyState";

import PortifolioClient from "./PortifolioClient";
import { useLocation} from "react-router-dom";
import { ProjectService } from "../../services/ProjectService";
import Loader from "../../components/Loader";
import Project from "../../models/Project";




const PortifolioPage = () => {

  const pathName = useLocation().pathname;
  const projectService = useMemo(() => new ProjectService(), []);

  
  const [project, setProject] = useState<Project| null>(null);
  const [isLoading, setIsLoading] = useState(false);

  

  useEffect(() => {
    setIsLoading(true)
    const identifier = pathName;
    const initialize = async () => {
      try {
        const projectData = await projectService.getProjectByIdentifier(identifier);
        setProject(projectData);
      } catch (err) {
        
      } finally {
        setIsLoading(false);
      }
    };
    initialize();
  }, [pathName]);

  if(isLoading){
    return(
      <Loader/>
    )
  }

  if (!project) {
    return (      
        <EmptyState 
          title="Artista não encontrado."
          subtitle="Tente procurar outro artista"
          showReset = {false}
        />      
    );
  }
  
  return (
    <>
      <PortifolioClient
        project={project}
      />
    </>
  );
}
 

export default PortifolioPage;