import React from "react";

interface ISpotfyIframe{
    artistId : string    
}


const SpotifyIframe: React.FC<ISpotfyIframe> = ({
    artistId
})=>{

  function extractSpotifyUsername(input:string) {
      const regex = /https:\/\/open\.spotify\.com\/(?:intl-\w+\/)?(user|artist|album|track)\/([^/?]+)/;
    
      const match = input.match(regex);
  
      if (match && match[2]) {
        return match[2]; 
      }

      if (/^[a-zA-Z0-9]+$/.test(input)) {
        return input;
      }

    }

    const artistIdCleaned = extractSpotifyUsername(artistId)

    return (
        <iframe
          title="Spotify"
          className="w-full h-64 md:h-96"  
          src={`https://open.spotify.com/embed/artist/${artistIdCleaned}`}
          allowTransparency={true}
          allow="encrypted-media"
        ></iframe>
      );

}
export default SpotifyIframe