import React from "react";

interface ISimpleInput{
    value : any;
    onChange: (e : any) => void;
    id: string;
    label?: string;
    onBlur?: () => void;
    placeholder: string;
    
}


const SimpleInput: React.FC<ISimpleInput> = ({
    value,
    onChange,
    onBlur,
    id,
    label,
    placeholder
})=>{


    return(
        <div
            className="w-full"
        
        >
        {label && 
            <label
             htmlFor={id}
             className={`
               text-sm
               z-5
             `}
             >
              {label}
            </label>
        }
        <input
            id = {id}
            placeholder={placeholder}
            type="text"
            className="my-2 peer w-full py-1.5 px-2.5 pr-8 font-light bg-customCards border-2 border-customCards focus:border-lilas leading-12px rounded-md outline-none transition disabled:opacity-70 disabled:cursor-not-allowed"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
        </div>

    )
}

export default SimpleInput