import { create } from "zustand";

interface IPortifolioModalStore {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const usePorifolioModal = create<IPortifolioModalStore>((set) => ({
  isOpen: false,
  project: null,
  onOpen: () => {
    set({ isOpen: true });
  },
  onClose: () => set({ isOpen: false }),
}));

export default usePorifolioModal;
