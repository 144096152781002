import React from "react";
import { IconType } from "react-icons";

interface ISelectButton {
  label: string;
  onClick: () => void;
  selected?: boolean;
  icon: IconType;
  vertical?: boolean;
}

const SelectButton: React.FC<ISelectButton> = ({
  icon: Icon,
  label,
  onClick,
  selected,
  vertical,
}) => {
  return (
    <button
      className={`flex items-center gap-1  cursor-pointer 
        ${selected ? "text-lilas cursor-not-allowed" : "hover:text-lilasHover"} 
        ${vertical ? "flex-col text-xs w-full" : "flex-row"}
      `}
      onClick={onClick}
    >
      <Icon size={vertical ? 28 : 16} />
      <label
        className={`cursor-pointer
        `}
      >
        {label}
      </label>
    </button>
  );
};

export default SelectButton;
