import React from 'react'
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

interface IMenuPagination{
    onPreviousPage : ()=>void;
    onNextPage: ()=> void;
    currentPage: number;
    totalPages:number
}

const MenuPagination: React.FC<IMenuPagination> = ({
    onNextPage,
    onPreviousPage,
    currentPage,
    totalPages
}) => {
    return(
        <div className="flex gap-2 justify-between ">
            {totalPages > 1 &&
            <>
                <button
                  onClick={onPreviousPage}
                  disabled={currentPage === 1}
                  className="p-1 bg-lilas rounded-full text-customModal
                    disabled:bg-customBackground 
                    "
                >
                  <GoChevronLeft />
                </button>
                <button
                  onClick={onNextPage}
                  disabled={currentPage === totalPages}
                  className="p-1 bg-lilas rounded-full text-customModal
                    disabled:bg-customBackground "
                >
                    <GoChevronRight/>
                </button>
            </>
            }
        </div>
    )
}

export default MenuPagination