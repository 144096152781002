import React, { useEffect, useState } from "react";
import Input from "./Input";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { CiCirclePlus } from "react-icons/ci";
import { RiCloseCircleFill } from "react-icons/ri";
import ImageUploader from "./Uploads/ImageUploader";
import AvatarUploader from "./Uploads/AvatarUploader";
import SimpleInput from "./SimpleInput";

const path = process.env.REACT_APP_MINIO_URL;

type InputData = {
  label1: string;
  label2: string;
  label3: string;
};

type InputItemCard = {
  id1: string;
  id2: string;
  id3: string;
  label1: string;
  label2: string;
  label3: string;
  value1: string;
  value2: string;
  value3: string[];
  index: number;
  control: Control<FieldValues, any>;
  imagePathName: string;
  onChange: (field: any, value: string) => void;
  active: boolean;
  onRemove: () => void;
  onActivate: () => void;
  title: string;
};

const InputItemCard: React.FC<InputItemCard> = ({
  id1,
  id2,
  id3,
  label1,
  label2,
  label3,
  value1,
  value2,
  value3,
  onChange,
  onRemove,
  onActivate,
  active = false,
  index,
  imagePathName,
  control,
  title,
}) => {
  const [profileImage, setProfileImage] = useState<string[]>(value3);

  const handleProfileUpload = (files: string[]) => {
    setProfileImage(files);
  };

  useEffect(() => {
    setProfileImage(value3);
  }, [value3]);

  return (
    <div className="relative flex flex-col gap-5 border-customBackground aspect-[350/360] border-[1px] rounded-[6px] p-2 w-full">
      {active ? (
        <>
          <button
            className="absolute top-5 right-0 flex items-center justify-center px-2 text-redError hover:text-customRed"
            onClick={onRemove}
          >
            <RiCloseCircleFill size={12} />
          </button>
          <label className="text-xs">Dados sobre o {title}</label>
          <div className="flex flex-col gap-5 ">
            <section className="w-full  h-[250px]">
              <Controller
                name={id3}
                control={control}
                render={({ field }) => (
                  <ImageUploader
                    onSetPathValue={(value: string) => {
                      field.onChange(value);
                      onChange("photo", value);
                    }}
                    pathAndName={`${imagePathName}/photos/imagem-${index + 1}`}
                    imagens={profileImage}
                    maxFiles={1}
                    onUpload={handleProfileUpload}
                  >
                    {profileImage.length > 0 && profileImage[0] && (
                      <AvatarUploader
                        big
                        src={
                          profileImage[0].includes("blob")
                            ? profileImage[0]
                            : path + profileImage[0]
                        }
                      />
                    )}
                  </ImageUploader>
                )}
              />
            </section>
            <Controller
              name={id1}
              control={control}
              render={({ field }) => (
                <SimpleInput
                  placeholder={"insira o " + label1}
                  id={id1}
                  onChange={(e) => {
                    field.onChange(e);
                    onChange("name", e.target.value);
                  }}
                  value={value1}
                  label={label1}
                />
              )}
            />
            <Controller
              name={id2}
              control={control}
              render={({ field }) => (
                <SimpleInput
                  placeholder={"insira a " + label2}
                  id={id2}
                  onChange={(e) => {
                    field.onChange(e);
                    onChange("subName", e.target.value);
                  }}
                  value={value2}
                  label={label2}
                />
              )}
            />
          </div>
        </>
      ) : (
        <>
          <section
            onClick={onActivate}
            className="flex flex-col justify-center items-center text-lilasHover h-full"
          >
            <CiCirclePlus size={250} />
          </section>
        </>
      )}
    </div>
  );
};

export default InputItemCard;
