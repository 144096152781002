import React from "react";

interface CustomSizePictureProps{
    src: string | null | undefined;
    rounded?: boolean;
    width?:number;
    height?:number;
    shadow?: boolean;
    onClick?: ()=>void;
    border?:boolean;
    
}

const PATH = process.env.REACT_APP_MINIO_URL

const CustomSizePicture: React.FC<CustomSizePictureProps> = ({
    src,
    rounded,
    width,
    height,
    border,
    shadow,
    onClick
}) =>{
    return(
        <img
            onClick={onClick}
            alt="BigPicture"
            className={`
                ${rounded? 'rounded-full object-cover':'rounded-md'} 
                ${border && 'border-[6px] border-lilasHover'}
                ${shadow && 'shadow-2xl'}
            `}
            style={{
                height: (height || 'auto'),
                width: (width || 'auto'),
                objectFit: 'cover'
            }}
            src={PATH && (PATH + src || "/images/placeholder.jpg")}
        />
        
    )
}

export default CustomSizePicture