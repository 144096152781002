import React from 'react';
import Select, {SingleValue } from 'react-select';
import { Control, Controller,FieldValues } from 'react-hook-form';
import Project from '../../../models/Project';

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#212123',
    color: '#C9C9C9',
    borderColor: state.isFocused ? '#6638FE' : '#212123', 
    boxShadow: state.isFocused ? '0 0 0 1px #6638FE' : 'none', 
    '&:hover': {
      borderColor: state.isFocused ? '#6638FE'  : '#212123', 
    },

  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#212123',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#6638FE' : state.isFocused ? '#4E35CD' : '#212123',
    color: '#C9C9C9',
  }),
  singleValue: (provided : any) => ({
    ...provided,
    color: '#C9C9C9', 
  }),
  placeholder: (provided : any) => ({
    ...provided,
    color: '#C9C9C9',
    fontSize: '0.75rem', 
    paddingLeft: '0.5rem',
  }),
  input: (provided : any) => ({
    ...provided,
    color: '#C9C9C9', 
    paddingLeft: '0.5rem',
  }),
  indicatorSeparator: (provided : any) => ({
    display: 'none',
  }),
};

type TOptions = {
  value: string;
  label: string;
  png?: string;
}


type ISelectComponent = {
    label: string;
    fieldName: string;
    placeholder: string;
    options: TOptions[];
    control: Control<FieldValues,any>;
    onChange?: (index: number, field: string, value: string)=>void;
    index?: number;
    value?:TOptions;
    changeValue?: string;
}

const SelectComponent: React.FC<ISelectComponent> = ({
    label,
    placeholder,
    options,
    control,
    fieldName,
    onChange,
    index,
    value,
    changeValue
}) => (
  <div className='w-full'>
      <p
        className={`
          text-sm
          z-5
        `}
      >
        {label}
      </p>
      <Controller
        name={fieldName}
        control={control}
        render={({field}) =>(
          <Select
            id ={fieldName}
            placeholder = {placeholder}
            styles={customStyles}
            options={options}
            value={value? value : null}
            onChange={(selectedOption: SingleValue<TOptions>) =>{
              if (onChange) {
                onChange((index||0), changeValue || 'name', (selectedOption?.value || ''));
              }
              field.onChange(selectedOption?.value)
            }}
          />
        )}
      />
    
  </div>
);

export default SelectComponent;