import React,{useState} from "react";
import PortifolioSection from "../PortifolioSection";
import CustomSizePicture from "../../photo/CustomSizePicture";
import useGaleryModal from "../../../hooks/useGaleryModal";
import MenuPagination from "./MenuPagination";
import {motion, AnimatePresence } from "framer-motion";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";


const path = process.env.REACT_APP_MINIO_URL


interface PortifolioGaleryProps{
    items:  string[]
}

const PortifolioGalery: React.FC<PortifolioGaleryProps> = ({
    items
}) =>{
    const galeryModal = useGaleryModal();  
    
    const itemsPerPage = 4;
    const [currentPage, setCurrentPage] = useState(1);


    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedItems = items.slice(startIndex, startIndex + itemsPerPage);
    
    const handleImageClick = (image: string, index: number) => {
        setSelectedImage(image);
        setSelectedImageIndex(index);
        galeryModal.onOpen()
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
        setSelectedImageIndex(null);
        galeryModal.onClose()
    };


    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);


      const handlePrevImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex !== null && prevIndex > 0 ? prevIndex - 1 : items.length));
        setSelectedImage((prevImage) => {
          if (prevImage && selectedImageIndex !== null && selectedImageIndex > 0) {
            return items[selectedImageIndex - 1];
          }
          return prevImage;
        });
      };
      
      const handleNextImage = () => {
        setSelectedImageIndex((prevIndex) => {
          if (prevIndex !== null && prevIndex < items.length - 1) {
            return prevIndex + 1;
          }
          return -1;
        });
        setSelectedImage((prevImage) => {
          if (prevImage && selectedImageIndex !== null && selectedImageIndex < items.length - 1) {
            return items[selectedImageIndex + 1];
          }
          return prevImage;
        });
      };

      const modalBodyContent = (
        <>
       
        
        </>

      )


    const handleBackgroundClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
          handleCloseModal();
        }
    }

    const body = (
      <div className="grid grid-cols-2  gap-4">
        <AnimatePresence>
          {paginatedItems.map((item, index) => (       
            <motion.div 
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.3 }}
            key={startIndex + index} 
            className="grid-1 h-auto w-auto rounded-lg"
            >
              <CustomSizePicture
                  height={220}
                  width={320}
                  onClick={() => handleImageClick(item,index)}
                  src={item}
              />
            </motion.div>
          ))}
        </AnimatePresence>

        {selectedImage && (
          <div className='
            justify-center
            items-center
            flex
            overflow-x-hidden
            overflow-y-auto
            fixed
            inset-0
            z-50
            outline-none
            focus:outline-none
          '
          onClick={handleBackgroundClick}
          >
            
            <div className="relative flex flex-row items-center justify-center w-[75%]" onClick={handleBackgroundClick}>
              <button
                onClick={handlePrevImage}
                className="
                    p-1
                    bg-lilas 
                    rounded-full 
                    text-customModal
                    absolute
                    left-0
                  "
              >
                <GoChevronLeft size={30}/>
              </button>
                
              <img src={path + selectedImage} alt="Selected Image" className="w-auto max-w-[90%]" />
              <button
                onClick={handleNextImage}
                className="
                    p-1
                    bg-lilas
                    rounded-full
                    text-customModal
                    absolute
                    right-0
                  "
              >
                  <GoChevronRight size={30}/>
              </button>
            </div>
          </div>  
        )}
      </div>
    )

    const totalPages = Math.ceil(items.length / itemsPerPage);

    const handleNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const handlePreviousPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };


    const buttons = (
      <MenuPagination
        currentPage={currentPage}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
        totalPages={totalPages}
      />
    )


    return(
        <>
            <PortifolioSection
                 title="Fotos"
                 body={body}
                 buttons={buttons}
            />
          
       </>
    )
}

export default PortifolioGalery