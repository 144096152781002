import React from 'react'
import './missing.css'

interface IMissingToolTip{
    missingFields : string[]
}

const MissingToolTip: React.FC<IMissingToolTip> = ({missingFields}) => {
    return (
        <>
            {missingFields.length > 0 ?(
                <div className="fixed bottom-[4%] right-[7%]">
                    <div className="relative group cursor-pointer">
                      <span className="text-yellow-500">⚠️ Campos para ativação</span>
                      <div className="absolute hidden group-hover:block bg-gray-800 text-white text-sm rounded-md p-2 mb-2 bottom-full right-0">
                        {
                          missingFields.map((field) => (
                            <div key={field}>{field}</div>
                          ))
                        }
                      </div>
                    </div>
                </div>
            ):
            <div></div>
            }
        </>
    );
}

export default MissingToolTip