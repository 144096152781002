import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { NumericFormat } from "react-number-format";

interface InputProps {
  id: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  register: UseFormRegister<FieldValues>;
  Placeholder?: string;
  value?: number
}

const InputPrice: React.FC<InputProps> = ({
  id,
  label,
  disabled,
  required,
  register,
  Placeholder,
  value = ''
}) => {
  return (
    <div className="w-full relative">
      <label
        htmlFor={id}
        className="text-sm z-5 text-customText"
      >
        {label}{required && <label className="text-verde">*</label>}
      </label>
      <NumericFormat
        id={id}
        disabled={disabled}
        placeholder={Placeholder}
        className={`
          peer
          w-full
          py-1.5
          px-2.5
          font-light
          bg-customCards
          border-2
          leading-12px
          rounded-md
          outline-none
          transition
          focus:inherit
          filled:inherit
          disabled:opacity-70
          disabled:cursor-not-allowed
          border-customCards
          focus:border-lilas
        `}
        thousandSeparator="."
        decimalSeparator=","
        prefix="R$ "
        decimalScale={2}
        fixedDecimalScale={true}
        value={value}
        onValueChange={(values) => {
          const { floatValue } = values;
          register(id).onChange({
            target: {
              value: floatValue || '', 
              name: id,
            }
          });
        }}
      />
    </div>
  );
};

export default InputPrice;