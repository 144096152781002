import React from "react";
import { useNavigate } from "react-router-dom";



const Logo = () =>{
    
    const navigate = useNavigate();

    //TODO retornar o hidden quando voltarmos com o search
    return(
        <img
        onClick={() => navigate('/')}
        alt="Logo"
        className="cursor-pointer"
        height={150}
        width={150}
        src="/images/logoOficial.png"       
        />
    )
}

export default Logo