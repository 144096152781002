import {useEffect,useMemo,useState} from "react";

import Container from "../../components/containers/Container";
import EmptyState from "../../components/EmptyState";
import PortifolioCard from "../../components/portifolio/PortifolioCard";

import { ProjectService } from "../../services/ProjectService";
import AuthenticatedContainer from "../../components/containers/AuthenticatedContainer";
import Button from "../../components/Button";
import usePorifolioModal from "../../hooks/usePortifolioModal";
import ProjectCardDto from "../../models/ProjectCardDTO";
import ConfirmationDialog from "../../components/modals/ConfimarionDialogModal";
import useConfirmationModal from "../../hooks/useConfirmationModal";

const MyPortifolios = () =>{

    const portifolioModal = usePorifolioModal();
    const [portifolios,setPortifolios] = useState<ProjectCardDto[] | undefined>(undefined);
    const [projectName,setProjectName] = useState('');
    const [refresh,setRefresh] = useState(false)
    const projectService = useMemo(() => new ProjectService(), []);
    const { onOpen, setConfirmHandler, setRefuseHandler } = useConfirmationModal(state => ({
      onOpen: state.onOpen,
      setConfirmHandler: state.setConfirmHandler,
      setRefuseHandler: state.setRefuseHandler,
    }));

    useEffect(()=>{
      projectService.getMyProjects().then(        
        (response) => {
          setPortifolios(response)    
        }
      ).catch()
    },[projectService,refresh])

   
 
  
    
    const menu = (
      <section 
        className="
          flex
          flex-row
          items-center
          w-11/12
          md:w-2/12
          gap-5
          py-4
        "
      >
        <Button
          label="Criar projeto"
          small
          onClick={portifolioModal.onOpen}
        />

      </section>
    )

    const handleDelete = (index: number) =>{
      if (portifolios){
        projectService.deleteProject(portifolios[index].id).then((response)=>{
          setRefresh(!refresh)
        }).catch((error)=>{
        })
      }
    }

   
  
  
    const handleConfirm = (index:number) => {
      handleDelete(index)
    };
  
  
    const handleClick = (index:number) => {
      setConfirmHandler(()=>handleConfirm(index));
      setProjectName( portifolios?  portifolios[index].name : '');
      onOpen();
    };


    if (portifolios?.length === 0) {
      return (
        <AuthenticatedContainer title="Meus Projetos" menu={menu}>        
          <EmptyState 
            title="Você ainda não possui nenhum projeto."
            subtitle="Clique em Crie seu portifolio no canto esquerdo"
          />
        </AuthenticatedContainer>
      );
    }


    return(
      <AuthenticatedContainer title="Meus Projetos" menu={menu}>
          <div 
            className="
              grid 
              grid-cols-1 
              sm:grid-cols-2 
              md:grid-cols-3 
              lg:grid-cols-4
              gap-8
            "
          >
            {portifolios?.map((project,index) => (
              <PortifolioCard
                key={index}
                data={project}
                onClick={()=>handleClick(index)}
                isEditing
              />
            ))}
          </div>
          <ConfirmationDialog
            message={`Tem certeza que deseja excluir o projeto ${projectName}`}
          />
      </AuthenticatedContainer>
    )
}

export default MyPortifolios