import React from "react";
import SelectButton from "../containers/SelectButton";
import { RxAvatar } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import useLoginModal from "../../hooks/useLoginModal";
import { useLocation, useNavigate } from "react-router-dom";
import useRegisterModal from "../../hooks/useRegisterModal";
import { useAuth } from "../../hooks/useAuth";
import { MdOutlineLogout } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";

const MobileScreenFooter = () => {
  const loginModal = useLoginModal();
  const registerModal = useRegisterModal();
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const { user, logout } = useAuth();

  const handleNavigate = () => {
    navigate("/");
  };

  const handleNavigateIntern = () => {
    navigate("/user/myprojects");
  };

  return (
    <div
      className="
        flex
        flex-row
        justify-center
        items-center
        md:hidden
        bg-customModal
        w-full
        px-8
      "
    >
      <label className="w-1/5 align-center">
        <SelectButton
          icon={IoSearchOutline}
          label="Explorar"
          onClick={() => {
            registerModal.onClose();
            loginModal.onClose();
            handleNavigate();
          }}
          vertical
          selected={isHomePage && !loginModal.isOpen && !registerModal.isOpen}
        />
      </label>
      {user ? (
        <>
          <label className="w-1/5">
            <SelectButton
              icon={HiDotsVertical}
              label="Gerenciar"
              onClick={handleNavigateIntern}
              selected={loginModal.isOpen || registerModal.isOpen}
              vertical
            />
          </label>
          <label className="w-1/5">
            <SelectButton
              icon={MdOutlineLogout}
              label="Sair"
              onClick={logout}
              vertical
            />
          </label>
        </>
      ) : (
        <label className="w-1/5">
          <SelectButton
            icon={RxAvatar}
            label="Entrar"
            onClick={loginModal.onOpen}
            selected={loginModal.isOpen || registerModal.isOpen}
            vertical
          />
        </label>
      )}
    </div>
  );
};

export default MobileScreenFooter;
