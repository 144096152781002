import React, { useState } from 'react'
import PortifolioSection from '../PortifolioSection'
import MusicLinks from '../../../models/MusicLinks'
import SpotfyIframe from './SpotifyIframe'
import SoundCloudIframe from './SoundCloudIframe'
import DeezerIframe from './DeezerIframe'
import { AnimatePresence,motion } from 'framer-motion'
import { FaSpotify } from "react-icons/fa";
import { FaDeezer } from "react-icons/fa";
import { FaSoundcloud } from "react-icons/fa";


import SelectButton from '../../containers/SelectButton'

interface IPortifolioMusics{
    musicLinks: MusicLinks
}

const getFirstNonNullField = (obj: any) => {
    if (obj.spotify !== null && obj.spotify !== "") {
        return 'spotify';
    }
    
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== "") {
            return key;
        }
    }
    return "";
};
  
const PortifolioMusics: React.FC<IPortifolioMusics> = ({
    musicLinks
}) => {
    
    //Pega todos os campos não nulos
    const filteredMusicLinks = Object.entries(musicLinks)
    .filter(([key, value]) => value !== null && value !== "")
    .reduce((acc, [key, value]) => {
      acc[key as keyof MusicLinks] = value;
      return acc;
    }, {} as Partial<MusicLinks>);



    const [currentIframe, setCurrentIframe] = useState(() => getFirstNonNullField(musicLinks));


    


    const buttons = (
        <div className='flex flex-row gap-3' >
            {filteredMusicLinks.spotify &&
                <SelectButton
                    icon={FaSpotify}
                    label='Spotify'
                    onClick={() => setCurrentIframe("spotify")}
                    selected={currentIframe === "spotify"}
                />
            }
            {filteredMusicLinks.deezer &&
                <SelectButton
                    icon={FaDeezer}
                    label='Deezer'
                    onClick={() => setCurrentIframe("deezer")}
                    selected={currentIframe === 'deezer'}
                />
            }
            {filteredMusicLinks.soundCloud &&
                <SelectButton
                    icon={FaSoundcloud}
                    label='SoundCloud'
                    onClick={() => setCurrentIframe("soundCloud")}
                    selected={currentIframe === 'soundCloud'}
                />
            }
        </div>
    )

    const body = (
        <div>
           {currentIframe == "spotify" &&
                <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, x: -400 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 400 }}
                      transition={{ duration: 1 }}
                      className="relative flex flex-row"
                    >
                    <SpotfyIframe
                        artistId={musicLinks.spotify}
                    />
                    </motion.div>
                </AnimatePresence>
           }
            {currentIframe == "soundCloud" &&
                <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, x: 400 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -400 }}
                      transition={{ duration: 1 }}
                      className="relative flex flex-row"
                    >
                        <SoundCloudIframe
                            url={musicLinks.soundCloud}
                        />
                    </motion.div>
                </AnimatePresence>
            }
            {currentIframe == "deezer" &&
                <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, x: -400 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 400 }}
                      transition={{ duration: 1 }}
                      className="relative flex flex-row"
                    >
                    <DeezerIframe
                        url={musicLinks.deezer}
                    />
                    </motion.div>
                </AnimatePresence>
            }
             
        </div>
    )



    return (
      <PortifolioSection
          title="Música"
          body={body}
          buttons={buttons}
      />
    )
}

export default PortifolioMusics