import React, { useEffect, useState } from 'react'
import EmptyState from '../../components/EmptyState'
import { UserService } from '../../services/UserService'
import { useParams } from 'react-router-dom';
import { error } from 'console';

const ActiveAccout = () => {

  const [title,setTitle] = useState('Aguarde');
  const [subTitle,setSubTitle] = useState('');
  const [showReset,setShowReset] = useState(false);

  const { token } = useParams();


  useEffect(()=>{
    const userService = new UserService();

    userService.activateAccount(token as string).then((response)=>{
      setSubTitle('comece a navegar');
      setTitle('Parabéns conta ativada com sucesso');
      setShowReset(true);
    }).catch(error=>{
      setTitle('Token não encontrado');
    });
  },[])
  
  return (
    <EmptyState
        title={title}
        subtitle={subTitle}
        buttonLabel='Seja bem vindo!'
        showReset = {showReset}
    />
  )
}

export default ActiveAccout