import React from "react";
import { useAuth } from "../../hooks/useAuth";
import useLoginModal from "../../hooks/useLoginModal";
import { HiDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router-dom";


const Login = () =>{
     
    const {user, logout} = useAuth(); 
    const loginModal = useLoginModal();
    const navigate = useNavigate();

    const handleLogin = (()=>{
        loginModal.onOpen();
    })

    const handleNavigate = (()=>{
        navigate('/user/myprojects')
    })

    return(
        
        <div
            onClick={user? handleNavigate : handleLogin}
            className="
                md:py-1
                md:px-2
                items-center
                justify-center
                cursor-pointer
                transition
                text-lilas
                flex 
                flex-col
            "
        >
            {user?
            <div className="flex flex-row items-center">
                <div className="flex flex-col items-end hidden md:flex">
                    <span className="text-xs font-bold m-0 p-0">
                        Acessar
                    </span>
                    <span className="text-sm xl:text-base font-black m-0 p-0 ">
                        Meu GIGSET
                    </span>
                </div>
                <span className="text-3xl">         
                    <HiDotsVertical />
                </span>
            </div> 
            : 
            <>
                <span className="text-xs hidden sm:flex font-bold m-0 p-0">
                    Já tem conta?
                </span>
                <span className="text-base font-black m-0 p-0 ">
                    Entrar
                </span>
            </>}
            
        </div>
        
    )
}

export default Login