import React, { useState } from 'react'
import ItemText from '../../../models/ItemText';
import PortifolioSection from '../PortifolioSection';
import {motion} from 'framer-motion'
import { FaChevronDown,FaChevronUp  } from "react-icons/fa";
import {IconType} from 'react-icons';


interface IPortifolioFaq{
    faq : ItemText[];
}


const PortifolioFaq: React.FC<IPortifolioFaq> = ({
    faq
}) => {

    const [openIndex, setOpenIndex] = useState<number|null>(null);

    const handleToggle = (index: number) => {
      setOpenIndex(openIndex === index ? null : index);
    };


    const body =(
        <div>
            {faq.map((item, index) => item.item && (
              <div key={index} >
                <button
                  onClick={() => handleToggle(index)}
                  className={`
                    block
                    w-full
                    text-left
                    p-2
                    pl-8
                    border
                    border-x-0
                    border-lilas
                    cursor-pointer
                    flex
                    flex-row
                    items-center
                    justify-between
                    ${openIndex === index ? ' text-lilas' : 'border-b-0'}
                  `}
                  
                >
                  {item.item}
                  {openIndex === index ?  <FaChevronUp size={12}/> : <FaChevronDown size={12}/> }
                </button>
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: openIndex === index ? 'auto' : 0, opacity: openIndex === index ? 1 : 0 }}
                  transition={{ duration: 0.3 }}
                  style={{ overflow: 'hidden' }}
                >
                  <div className='p-10 pl-20 '>
                    {item.content}
                  </div>
                </motion.div>
              </div>
            ))}
        </div>
    )


  return (
    <PortifolioSection
            title="Perguntas frequentes"
            body={body}
       />
  )
}

export default PortifolioFaq