import React from "react";
import { IconType } from "react-icons";

interface IButtonProps {
    label: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    register?: boolean;
    small?: boolean;
    icon?: IconType;

}

const Button: React.FC<IButtonProps> = ({
    label,
    onClick,
    disabled,
    register,
    small,
    icon: Icon    
}) =>{
    return(
        <button
            onClick={onClick}
            disabled={disabled}
            className={`
                relative
                disabled:opacity-70
                disabled:cursor-not-allowed
                rounded-lg
                transition
                w-full
                ${register? 'bg-verde':'bg-lilas'}
                ${register? 'hover:bg-verdeHover hover:border-verdeHover':'hover:bg-lilasHover hover:border-lilasHover'}
                ${register? 'border-verde':'border-lilas'}
                ${register? 'text-lilas':'text-lightGray'}
                ${small? 'py-1': 'py-1.5'}
                ${small? 'text-sm': 'text-base'}
                ${small? 'font-light ': 'font-bold'}
                ${small? 'border-[1px]': 'border-2'}
            `}
        >
            {Icon && (
                <Icon
                    size={24}
                    className="
                        absolute
                        left-4
                        top-3
                    "
                />
            )}
            {label}
        </button>        
    )
}

export default Button