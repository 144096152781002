import React from "react";

interface IDeezerIframe{
    url : string    
}


const DeezerIframe: React.FC<IDeezerIframe> = ({
    url
})=>{
    return (
        <iframe 
        title="deezer-widget" 
        src={url}
        className="w-full h-64 md:h-96 rounded-lg"  
        allow="encrypted-media; clipboard-write"></iframe>
      );

}
export default DeezerIframe