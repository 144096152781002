import React from "react";
import PortifolioSection from "../PortifolioSection";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules'
import 'swiper/css/scrollbar';
import "swiper/css";
import RoundPhotoBox from "../../containers/RoundPhotoBox";
import ItemCard from "../../../models/ItemCard";




interface PortifolioItemCardProps{
    items?:  ItemCard[];
    title: string;
}

const PortifolioItemCard: React.FC<PortifolioItemCardProps> = ({
    items,
    title
}) =>{
    
    const body = (
        <div>
            <Swiper
                scrollbar={{
                    hide: true,
                  }}
                modules={[Scrollbar]}
                slidesPerView={2}
                breakpoints={{
                    768: {
                      slidesPerView: 3,
                    },
                    920:{
                        slidesPerView: 4,
                    }
                  }}               
                autoplay={{
                    delay: 3000, 
                    stopOnLastSlide: false,
                }}
                className="
                    flex
                    flex-row
                    items-center
                    justify-between
                    overflow-x-auto
                "
            >
            {items?.map((item)=>(
                <SwiperSlide
                    key={item.name}
                >
                {
                    item.name &&
                    <RoundPhotoBox
                        key={item.name}
                        src={item.photo}
                        title={item.name}
                        subtitle = {item.subName}
                    />
                }
                    
                </SwiperSlide>
            ))}
            </Swiper>      
        </div>
    )

    return(
       <PortifolioSection
            title={title}
            body={body}
       />
    )
}

export default PortifolioItemCard