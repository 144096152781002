import React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form';

interface IContactInputs{
  register: UseFormRegister<FieldValues>;

}

const ContactInputs: React.FC<IContactInputs> = ({
    register
}) => {


  const today = new Date().toISOString().split("T")[0];

  return (
    <div className='
        bg-white
        flex
        flex-col
        rounded-md
        p-2
        gap-1
        mt-4
        mb-4
        text-sm
        text-customModal
        

    '>
        <section className='flex flex-col border-b-2 border-customText'>
            <label className='text-xs'>Nome do evento</label>
            <input
                className='focus:outline-none'
                placeholder='Nome do evento'
                id='name'
                {...register('name')}
            />
        </section>
        <section
            className='
            flex
            flex-row
            gap-2
            border-b-2
            border-customText
            pb-1
            '
            
        >

            <section className='flex flex-col w-1/2 border-r-2 border-customText'>
                <label className='text-xs'>Data do evento</label>
                <input
                    id='date'
                    {...register('date')}
                    className='focus:outline-none'
                    placeholder='Data do evento'
                    type='date'
                    min={today}
                />
            </section>
            <section className='flex flex-col w-1/2'>
                <label className='text-xs'>Hora do evento</label>
                <input
                    id='hour'
                    {...register('hour')}
                    className='focus:outline-none'
                    placeholder='Hora do evento'
                    type='time'
                />
            </section>
        </section>
        <section className='flex flex-col border-b-2 border-customText'>
            <label className='text-xs'>Local do evento</label>
            <input
                id='city'
                {...register('city')}
                className='focus:outline-none'
                placeholder='Local do evento'
            />
        </section>
        <section
            className='
            flex
            flex-row
            gap-2
            '
        >

            <section className='flex flex-col w-1/2 border-r-2 border-customText'>
                <label className='text-xs'>Horário da apresentação</label>
                <input
                    id='apresentationTime'
                    {...register('apresentationTime')}
                    className='focus:outline-none'
                    placeholder='Ex : 22:00 as 00:00'
                />
            </section>

            <section className='flex flex-col w-1/2'>
                <label className='text-xs'>Tempo do show</label>
                <input
                    id='time'
                    {...register('time')}
                    className='focus:outline-none'
                    placeholder='Tempo do show'
                    type='time'
                />
            </section>
           
    
        </section>


    </div>
  )
}

export default ContactInputs