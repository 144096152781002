import React, { useState } from "react";
import PortifolioSection from "../PortifolioSection";
import "swiper/css/scrollbar";
import "swiper/css";
import ItemText from "../../../models/ItemText";
import { motion } from "framer-motion";

interface PortifolioIncludedProps {
  items: ItemText[];
}

const PortifolioIncluded: React.FC<PortifolioIncludedProps> = ({ items }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedItems = isExpanded ? items : items.slice(0, 2);

  const body = (
    <div>
      <motion.div
        className="
                    grid
                    grid-cols-2
                    gap-6
                    text-sm
                    font-sm    
                    px-10  
                    
                "
        initial={{ height: "auto" }}
        animate={{ height: isExpanded ? "auto" : "auto" }}
        transition={{ duration: 0.3 }}
      >
        {displayedItems.map(
          (item, index) =>
            item.item && (
              <div
                key={index}
                className="
                            border-customBackground
                            border-2
                            rounded-md
                            flex
                            flex-col
                            gap-4
                            h-auto
                            h-min-[calc(1.25rem * 7)]
                        "
              >
                <label className="pl-2 text-lg">{item.item}</label>
                <p className="p-5 text-wrap">{item.content}</p>
              </div>
            )
        )}
      </motion.div>
      {items.length > 2 && (
        <button onClick={toggleExpand} className="text-lilas mt-2">
          {isExpanded ? "Ver menos" : "Ver mais"}
        </button>
      )}
    </div>
  );

  return (
    <PortifolioSection
      title="O que pode estar incluso na apresentação"
      body={body}
    />
  );
};

export default PortifolioIncluded;
