import React, { useState } from 'react';
import { MdModeEditOutline } from 'react-icons/md';
import { motion } from 'framer-motion';

interface IDescriptionComponent{
  description: string,
}

const DescriptionComponent: React.FC<IDescriptionComponent> = ({description}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='relative'>
      <motion.article
        className="w-[98%] font-light overflow-hidden text-pretty whitespace-pre-line "
        initial={{ height: 'auto' }}
        animate={{ height: isExpanded ? 'auto' : 'calc(1.25rem * 5)' }}
        transition={{ duration: 0.3 }}
      >
        {description}
      </motion.article>

      {description.split(' ').length > 24 && ( 
        <button
          onClick={toggleExpand}
          className="text-lilas mt-2"
        >
          {isExpanded ? 'Ver menos' : 'Ver mais'}
        </button>
      )}
    </div>
  );
};

export default DescriptionComponent;