import { useEffect, useState } from "react";
import qs from 'query-string'
import {BiSearch} from 'react-icons/bi'
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSearch } from "../../context/SearchContext";


const Search = () =>{

    const [name, setName] = useState('');
    const navigate = useNavigate(); 
    const [params] = useSearchParams();
    const {setSearchQuery} = useSearch();

    const handleSearch = () =>{
        let currentQuery = {};
        
        if (params) {
          currentQuery = qs.parse(params.toString())
        }
    
        const updatedQuery: any = {
          ...currentQuery,
          name: name
        }
    
        if (params?.get('name') === name) {
          delete updatedQuery.name;
        }
    
        const url = qs.stringifyUrl({
          url: '/',
          query: updatedQuery
        }, { skipNull: true });
    

        

        navigate(url);
    }

    useEffect(()=>{
        setSearchQuery(params.toString());
    },[params])

    return(
        <div
            className="
                hidden
                md:flex
                w-full
                md:w-auto
                rounded-full
                shadow-sm
                hover:shadow-md
                transition
                cursor-pointer
                bg-customBackground
            "
        >
            <div
                className="
                    flex
                    flex-row
                    items-center
                    justify-between
                "
                translate="no" 
            >
                <input
                    placeholder="Buscar por artistas"
                    className={`
                        peer
                        w-full
                        py-1.5
                        px-2.5
                        font-light
                        bg-customBackground
                        rounded-l-full
                        leading-12px
                        rounded-md
                        outline-none
                        transition
                        text-sm
                        pr-20
                      `}            
                      style={{ fontWeight: 'bold' }}
                    onChange={(e)=>setName(e.target.value)}
                    value={name}
                />
                
                <div
                    className="
                        text-sm
                        pl-3
                        flex
                        flex-row
                        items-center
                        justify-center
                        transition
                        bg-lilas
                        hover:bg-lilasHover
                        rounded-r-full
                    "
                >
                    <div
                        className="
                            p-2 
                            pr-4       
                        "
                        onClick={handleSearch}
                    >
                        <BiSearch size ={18}/>
                    </div>
                </div>
            </div>            
        </div>
        
    )
}

export default Search