import React from "react";

interface IAvatarUploader{
        src: string | null | undefined;
        big?: boolean;
}

const AvatarUploader : React.FC<IAvatarUploader>   = ({
    src,
    big
}) =>{


    return(
        <img
          alt="Avatar"
          className={
            `rounded-full
            border-[8px]
            border-lilasHover
            object-cover
            `
          }
          style={{
            height: (220 || 'auto'),
            width: (220 || 'auto'),
            objectFit: 'cover',
            marginTop:'15px',
            marginRight: '15px' 
        }}
          src={src || "/images/avatar.png"}
        />
    )



}

export default AvatarUploader