import React, { useEffect, useState } from "react";
import WideScreenFooter from "./WideScreenFooter";
import MobileScreenFooter from "./MobileScreenFooter";

const Footer = () => {
  const [scrollingDown, setScrollingDown] = useState(false);

  useEffect(() => {
    let lastScrollTop = 0;
    const threshold = 500;

    const handleScroll = () => {
      let scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (Math.abs(scrollTop - lastScrollTop) > threshold) {
        if (scrollTop > lastScrollTop) {
          setScrollingDown(true);
        } else {
          setScrollingDown(false);
        }

        lastScrollTop = scrollTop;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <footer
      className={`
        fixed
        bottom-0
        left-0
        w-full 
        z-50 
        bg-customModal 
        text-white 
        flex 
        flex-row
        p-2  
        md:p-0
        md:h-20 
        text-center 
        transition-transform 
        duration-300 
        border-t-darkGray
        border-t-[1px]
        md:border-t-0
        ${scrollingDown ? "translate-y-full" : "translate-y-0"}
        `}
    >
      <WideScreenFooter />
      <MobileScreenFooter />
    </footer>
  );
};

export default Footer;
