import React, { useEffect, useRef, useState } from "react";

import Container from "../../components/containers/Container";
import EmptyState from "../../components/EmptyState";
import PortifolioCard from "../../components/portifolio/PortifolioCard";

import { ProjectService } from "../../services/ProjectService";
import ProjectCardDto from "../../models/ProjectCardDTO";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSearch } from "../../context/SearchContext";

const Home = () => {
  const [portifolios, setPortifolios] = useState<ProjectCardDto[] | null>(null);
  const projectService = new ProjectService();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const { searchQuery } = useSearch();
  const loadMoreRef = useRef<HTMLParagraphElement | null>(null);
  const [loading, setLoading] = useState(false);

  const prevSearchQueryRef = useRef(searchQuery);

  useEffect(() => {
    setHasMore(true);
    setPage(() => 0);
    setPortifolios(null);
  }, [searchQuery]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      setPage(() => 0);
      setPortifolios(null);
      setHasMore(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (loading || !hasMore) return; // Evita chamadas se já estiver carregando ou não houver mais itens

    let searchingPage;
    prevSearchQueryRef.current !== searchQuery
      ? (searchingPage = 0)
      : (searchingPage = page);

    setLoading(true);
    projectService
      .getAllProjectsCards(searchingPage, searchQuery)
      .then((data) => {
        setPortifolios((prevPortifolios) => [
          ...(prevPortifolios || []),
          ...data.content,
        ]);
        setTotalPages(data.totalPages);
        setHasMore(data.totalPages > page + 1);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [page, searchQuery]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "200px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];

      if (target.isIntersecting && !loading && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }, options);

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [loading, hasMore, page]);

  if (portifolios?.length === 0) {
    return <EmptyState showReset />;
  }

  return (
    <Container>
      <div
        className="
              grid 
              grid-cols-1 
              sm:grid-cols-2 
              md:grid-cols-3 
              lg:grid-cols-4
              xl:grid-cols-5
              2xl:grid-cols-6
              gap-8
            "
      >
        {portifolios?.map((project) => (
          <PortifolioCard key={project.identifier} data={project} />
        ))}
      </div>
      <p ref={loadMoreRef}></p>
      {loading && (
        <div className="flex justify-center items-center py-4">
          <div className="w-8 h-8 border-4 border-t-4 border-gray-300 border-solid rounded-full animate-spin border-t-lilas"></div>
        </div>
      )}
    </Container>
  );
};

export default Home;
