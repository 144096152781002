import React from "react";
import { Link } from "react-router-dom";

interface IItemList {
    icon : string
    label : string
    path : string
}

const ItemList: React.FC<IItemList> = ({
    icon,
    label,
    path
}) =>{

    return(
        <li className="text-md hover:text-lilasHover"><Link className="flex flex-row items-center gap-4" to={path}><img src={icon}/><div>{label}</div></Link></li>
    )


}

export default ItemList