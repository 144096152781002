import React from 'react'
import Container from '../../components/containers/Container'
import Heading from '../../components/Heading'
import Button from '../../components/Button'
import Input from '../../components/inputs/Input'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { UserService } from '../../services/UserService'
import toast from 'react-hot-toast'

const RecoverPassword = () => {

    const {token} = useParams();
    const userService = new UserService();


    const {
        register,
        watch,
        handleSubmit,
        formState:{
            errors,
        },
    } = useForm<FieldValues>()

    const password = watch('newPassword')

    const validatePassword = (senha: string): boolean | string =>{
        if (senha.length < 8) {
            return 'Senha deve ter mais de 8 caracteres';
        }
        return true;
    }

    const validatePasswordEqual = (senha: string) : boolean | string =>{
        if (senha !== password) {
            return 'Senhas devem ser iguais';
        }
        return true;
    }

    const onSubmit: SubmitHandler<FieldValues> = (data) =>{
        data.token = token;
        userService.changePassword(data).then((response)=>{
            toast.success("Senha alterada com sucesso!")
        }).catch((error)=>{
            toast.error("Algo deu errado!")
        }).finally(()=>{

        });
    }


  return (
    <Container>
        <div
            className='
                flex 
                w-full
                justify-center
            
            '
        
        >
            <div
                className='
                    flex
                    flex-col
                    items-center
                    justify-center
                    bg-customBackground
                    w-2/5
                    rounded-lg
                    p-20
                    gap-4
                '
            >
                <Heading
                    title='Recuperar senha'
                    center
                />
                <Input
                    id='newPassword'
                    label='Nova Senha'
                    errors={errors}
                    register={register}
                    type='password'
                    validate={validatePassword}
                    needEvaluation
                    required
                />
                <Input
                    id='repeatNewPassword'
                    errors={errors}
                    register={register}
                    label='Repita a Nova Senha'
                    type='password'
                    validate={validatePasswordEqual}
                    passWordShow={false}
                />
                <Button
                
                    label='Confirmar'
                    onClick={handleSubmit(onSubmit)}
                />    

            </div>
        </div>
    </Container>
  )
}

export default RecoverPassword