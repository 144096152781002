import React from "react";
import PortifolioSection from "../PortifolioSection";
import PromoMaterial from "../../../models/PromoMaterial";
import Button from "../../Button";


interface PortifolioDownloadsProps{
    promoMaterial : PromoMaterial
}

const PortifolioDownloads: React.FC<PortifolioDownloadsProps> = ({
    promoMaterial
}) =>{


    const handleButtonClick = (link: string) => {
        window.open(link, '_blank');
    };
   
    const body = (
        <section
            className="
                flex
                flex-row
                justify-between
                gap-5
            "
        >
            {promoMaterial.preskit &&
                <Button
                    small
                    label="Press Kit"
                    onClick={()=>handleButtonClick(promoMaterial.preskit)}
                />
            }
           
            {promoMaterial.logo &&
                <Button
                    small
                    label="Logotipo"
                    onClick={()=>handleButtonClick(promoMaterial.logo)}
                 />
            }
            {promoMaterial.flyer &&
                <Button
                    small
                    label="Flyers"
                    onClick={()=>handleButtonClick(promoMaterial.flyer)}
                />
            }
            {promoMaterial.video &&
                <Button
                    small
                    label="Videos Promocionais"
                    onClick={()=>handleButtonClick(promoMaterial.video)}
                />
            }
            
           


        </section>

    )

    return(
       <PortifolioSection
            title="Materiais promocionais - Downloads"
            body={body}
       />
    )
}

export default PortifolioDownloads