import React from "react";

const WideScreenFooter = () => {
  const sendMessage = () => {
    const wppContact =
      "https://wa.me/5531996228270?text=Ola,%20estou%20vindo%20pelo%20gigset%20e%20gostaria%20de%20tirar%20uma%20duvida%20com%20voce.";
    window.open(wppContact, "_blank");
  };

  return (
    <div
      className="
            hidden
            w-full
            md:flex
            flex-row
          "
    >
      <div
        className="
          flex
          items-center
          justify-end
          mr-20
          w-[41%]
        "
      >
        <p>Artistas e produtores todos em um so lugar.</p>
      </div>
      <div
        className="
          flex
          flex-row
          w-[41%]
          bg-lilas
          items-center
          justify-end
        "
      >
        <p
          className="pr-10 hover:cursor-pointer text-sm md:text-base"
          onClick={sendMessage}
        >
          Alguma duvida? Entre em contato: (31) 99622-8270
        </p>
      </div>

      <div
        className="
          hidden
          
          h-full
          bg-verde
          md:flex
          flex-row 
          items-center
          justify-center
          gap-4
          w-[18%]
        "
      >
        <img height={50} width={50} src="GigsetSimbolo.png" />
        <p className="text-lilas font-bold ">© 2024 GIGSET</p>
      </div>
    </div>
  );
};

export default WideScreenFooter;
