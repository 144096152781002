import { create } from "zustand";

interface IConfirmationModalStore {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onConfirm: () => void;
  onRefuse: () => void;
  setConfirmHandler: (handler: () => void) => void;
  setRefuseHandler: (handler: () => void) => void;
}

const useConfirmationModal = create<IConfirmationModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  onConfirm: () => {},
  onRefuse: () => {},
  setConfirmHandler: (handler) => set({ onConfirm: handler }),
  setRefuseHandler: (handler) => set({ onRefuse: handler }),
}));

export default useConfirmationModal;
