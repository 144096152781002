import qs from 'query-string'
import React, {useCallback} from "react";
import { IconType } from "react-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomSizePicture from '../photo/CustomSizePicture';

interface RoundPhotoBoxProps {
    src?: string;
    title: string;
    subtitle: string;
}


const RoundPhotoBox: React.FC<RoundPhotoBoxProps>  = ({
    src,
    title,
    subtitle
}) =>{
    
 

    return(
        <div
            className="
                flex
                flex-col
                items-center
                justify-center
                gap-2
            "
        >
            <CustomSizePicture
                src={src}
                rounded
                height={126}
                width={126}
                border
            />
            <section
                className='
                    flex
                    flex-col
                    items-center
                    text-wrap
                    justify-center
                    text-center
                '
            >
                <span className='text-lg'>
                    {title}
                </span>
                <span className='text-sm'>
                    {subtitle}
                </span>
            </section>
        </div>
    )
}

export default RoundPhotoBox