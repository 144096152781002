import React from 'react';

import { FaCamera } from "react-icons/fa";

interface PortifolioHeadProps {
  imageSrc: string;
}


const PortifolioHead: React.FC<PortifolioHeadProps> = ({
  imageSrc,
}) => {

  return ( 
    <>
    <div className={`
          w-full
          md:h-[60vh]
          overflow-hidden 
          relative
          group
        `}
      >
        <img
          src={imageSrc}
          className="object-cover w-full h-full"
          alt="Image"
        />        
      </div>
    </>
   );
}
 
export default PortifolioHead;