import React from 'react'

import Button from "../../Button";
import { AiFillStar } from 'react-icons/ai';
import { NumericFormat } from "react-number-format";

import {
  FieldValues,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import ContactInput from '../../inputs/ContactInputs';
import { useNavigate } from 'react-router-dom';

interface PortifolioContactProps {
  minPrice?: number;
  maxPrice?: number;
  land_distance?: number;
  team?: number;
  review_note?: number;
  identifier?: string;
  coments_number?: number;
  disabled?: boolean;
  phone?: string;
  onClick?: () => void
}

const PortifolioContact: React.FC<
  PortifolioContactProps
> = ({
  minPrice,
  maxPrice,
  land_distance,
  team,
  identifier,
  review_note,
  coments_number,
  disabled, 
  phone,
  onClick
}) => {

  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };


  const {
      register,
      handleSubmit,

      reset
  } = useForm<FieldValues>({
      defaultValues:{
          name: "",
          date: "",
          hour: "",
          city: "",
          apresentationTime: "",
          time: "",
      }
  });



  const onContact: SubmitHandler<FieldValues> = (data) =>{
    let splitString = data.date.split("-")
    let reverseArray = splitString.reverse()
    let date = reverseArray.join("-")
    date = date.replaceAll("-","/")
    let wppContact = `https://api.whatsapp.com/send?1=pt_BR&phone=55${phone}&&text=Ola!%20vi%20o%20seu%20perfil%20na%20Gigset%20e%20venho%20entrar%20em%20contato%20para%20um%20or%C3%A7amento%20para%20um%20evento,%20dia:%20${date}%20no%20horario${data.hour}%20na%20cidade%20${data.city}.`
    if(data.name){
      wppContact = `https://api.whatsapp.com/send?1=pt_BR&phone=55${phone}&&text=Ola!%20vi%20o%20seu%20perfil%20na%20Gigset%20e%20venho%20entrar%20em%20contato%20para%20um%20or%C3%A7amento%20para%20o%20evento%20${data.name},%20dia:%20${date}%20no%20horario${data.hour}%20na%20cidade%20${data.city}.`
    }
    window.open(wppContact, '_blank');
    reset()
  };

  return ( 
    <div 
      className="
        sticky
        top-[200px]
        mt-8
        flex 
        flex-col
        gap-4
      "
    > 
      <label className='text-sm self-end hover:cursor-pointer' onClick={handleGoHome}>Voltar para busca</label>
      <div
        className='
        p-4
        rounded-xl 
        border-[1px]
        border-customText
        overflow-hidden
        
        '
      >
        <div className="flex flex-col gap-1 ">
          <div className=" flex flex-row  justify-between items-center gap-1">
            <label className="text-2xl text-lilas font-semibold" translate="no">
              {identifier}
            </label>
            <div className="flex flex-row text-sm gap-1 items-center">
              {review_note && 
              (<div className='flex flex-row items-center gap-1 '>
                <p className='text-lilas'><AiFillStar/></p>
                 {review_note}
              </div>)}
              {coments_number &&(
                <div className="px-2  text-sm">
                  {coments_number} comentarios
                </div>
              )}
            </div>
          </div>
        </div>
            
          <ContactInput
            register={register}
          />
            
        <div className='flex flex-col px-4 text-sm'>
          {((maxPrice && maxPrice > 0) || (minPrice && minPrice >0 ))?
          <div 
            className="
              flex 
              flex-row 
              items-center 
            "
          >
            <label>
            Faixa de cachê: 
            </label>
            {minPrice?

              
              <label className='flex text-lilas flex-row'>
                <NumericFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={minPrice}
                  displayType="text"
                />
                 &ensp;
                 a 
                 &ensp;
                <NumericFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={maxPrice}
                  displayType="text"
                />
              </label>
              :


              <label className='flex text-lilas flex-row'>
                <NumericFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={maxPrice}
                  displayType="text"
                />
              </label>
            }        
          </div>
          :<></>
          }
          <div 
            className="
              flex 
              flex-row 
              items-center 
            "
          >
            <label >
              Transporte (Terrestre):
            </label>
            <label className='text-lilas'>
              &ensp;{land_distance} Km
            </label>      
          </div>
          <div 
            className="
              flex 
              flex-row 
              items-center 
            "
          >
            <div>
              Equipe deslocada:
            </div>
            <label className='text-lilas'>
              &ensp;{team}
            </label>
          </div>
          </div>
          <div className="flex flex-col gap-2 py-4">
            <Button 
                disabled={disabled} 
                label="Solicitar orçamento" 
                onClick={handleSubmit(onContact)}
                small
            />
            <div className="
                items-center
                text-xs
                text-center	
              ">
                *Informações Prévias Estimadas
            </div>  
          </div>
        </div>
    </div>
   );
}
 
export default PortifolioContact;