import React, {useEffect} from "react";
import Container from "../containers/Container";

import { Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/scrollbar';
import "swiper/css";

import CategoryBox from "../containers/CategoryBox";
import { musicStyles } from "../../utils/StaticData";
import { useSearchParams, useLocation } from "react-router-dom";

const Categories = () =>{

    const [params] = useSearchParams();
    const category = params?.get('musicStyle');
    const pathName = useLocation().pathname;



    return(
        <Container>
            <Swiper
                scrollbar={{
                    hide: true,
                  }}
                modules={[Scrollbar]}
                slidesPerView={3}
                breakpoints={{
                    768: {
                      slidesPerView: 6,
                    },
                    920:{
                        slidesPerView: 9,
                    }
                  }}               
                
                className="
                    pt-4
                    flex
                    flex-row
                    items-center
                    justify-between
                    overflow-x-auto
                "
            >
            {musicStyles.map((item)=>(
                <SwiperSlide
                    key={item.label}
                >
                    <CategoryBox
                        key={item.label}
                        genero = {item.label}
                        identifier = {item.value}
                        png = {item.png}
                        selected={category === item.label}
                    />
                </SwiperSlide>
            ))}
            </Swiper>      
        </Container>
    )
}

export default Categories