import {useState} from "react";
import {  FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {IoMdClose} from 'react-icons/io'


import usePortifolioModal from "../../hooks/usePortifolioModal";

import Modal from "./Modal";
import Heading from "../Heading";
import Input from "../inputs/Input";
import { toast } from "react-hot-toast";
import { retrieveCepInformation } from "../../services/cepservice";
import { ProjectService } from "../../services/ProjectService";
import { useNavigate } from "react-router-dom";




const PortifolioModal = () =>{
    const portifolioModal = usePortifolioModal();
    const [isLoading, setIsLoading] = useState(false);
    const projectService =  new ProjectService();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState:{
            errors,
        },
    } = useForm<FieldValues>({
        defaultValues:{
        }
    })


    const onSubmit: SubmitHandler<FieldValues> = (data) => {
        setIsLoading(true);
        data.identifier =  data.identifier.toLowerCase().replace(/\s+/g, '');
        projectService.createProject(data)
        .then((response) => {
            navigate(`user/myprojects/${data.identifier}`,{state :data.identifier})
            toast.success('Portifolio criado!');
            portifolioModal.onClose();
        })
        .catch(() => {
          toast.error('Alguma coisa deu errado.');
        })
        .finally(() => {
          setIsLoading(false);
        })
      }



    const bodyContent=(
        <div className="flex flex-col gap-8">
          <Heading
            title="Como voce descreveria seu projeto?"
            subtitle="Informações basicas"
          />

          <button
                onClick={portifolioModal.onClose}
                className=
                    {`
                        md:hidden
                        p-1
                        border-0
                        hover:opacity-70
                        transition
                        absolute
                        top-12
                        right-0
                        text-lilas
                    `}
            >
                <IoMdClose size={18}/>
            </button>
          <Input
            id="name"
            label="Nome do projeto"
            disabled={isLoading}
            register={register}
            errors={errors}
            required
          />
          <hr />
          <Heading
            subtitle="Como você Gostaria que os usuarios encontrassem seu projeto?"
          />
          <Input
            id="identifier"
            label="Username do projeto"
            disabled={isLoading}
            register={register}
            errors={errors}
            required
          />
          <hr />
          <Input
            id="logisticDetail.phone"
            label="Teleofone DDD + número"
            disabled={isLoading}
            register={register}
            errors={errors}
            required
            mask="(99) 99999-9999"
          />
      </div>
    )
    //Localização
    // if (step === STEPS.LOCATION) {
        // bodyContent = (
          // <div
            // className="flex flex-col gap-8"
          // >
            {/* <Heading */}
              // title="Qual o endereço desse projeto?"
              // subtitle="Localize seu projeto no lugar mais proximo da sede!"
            // />
            {/* <Input   */}
              // id="zipcode"
              // label="Cep"
              // disabled={isLoading}
              // register={register}
              // errors={errors}
            // />
          {/* </div> */}
        // );
      // }

    return(
        <Modal
            isOpen = {portifolioModal.isOpen}
            onClose={portifolioModal.onClose}
            onSubmit={handleSubmit(onSubmit)}
            actionLabel="Criar"
            title = "Crie seu projeto"
            body={bodyContent}
        />        
    )
}

export default PortifolioModal