import axiosInstance from "./axios";

const route = "/files";
//TODO types
export class FileService {
  async uploadFile(data: any) {
    return await axiosInstance.post(route, data);
  }

  async deleteFile(data: any) {
    return await axiosInstance.delete(route + "/upload", data);
  }
}
