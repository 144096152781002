import React, { useEffect, useState } from 'react';
import { RiCloseCircleFill } from 'react-icons/ri'; 
import { CiCirclePlus } from "react-icons/ci";
import { motion, AnimatePresence } from 'framer-motion';
import { Control, FieldValues, useFieldArray } from 'react-hook-form';
import SimpleInput from './SimpleInput';

interface IDynamicInputs{
  control : Control<FieldValues,any>;
  fieldName: string;
  initialValues: string[] | undefined;
}


const DynamicInputs: React.FC<IDynamicInputs> = ({
  control,
  fieldName,
  initialValues
}) => {

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName
  });
  
  const [inputs, setInputs] = useState(initialValues? [...initialValues, [''] ]: ['']);

  useEffect(() => {
    if (initialValues) {
      setInputs([...initialValues,['']]);
    }
  }, [initialValues]);
  
  const addInput = () => {
    setInputs([...inputs, '']); 
  };

  const handleInputChange = (index : number, value : any) => {
    setInputs(prevInputs => {
      const newInputs = [...prevInputs];
      
      newInputs[index] = value;
      
      if (index === prevInputs.length - 1 && value.trim() !== '') {
        return [...newInputs, '']; 
      }
  
      return newInputs; 
    });


  };

  const handleInputBlur = (index:number) => {
    append(inputs[index])
  };

 

  const removeInput = (index : number) => {
    const newInputs = inputs.filter((_, i) => i !== index); 
    setInputs(newInputs);
    remove(index)
  };


  return (
    <div className="">
      <div className="">
      <AnimatePresence>
        {inputs.map((input, index) => (
          <motion.div
              key={index}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.3 }}
              className="relative"
            >
              <SimpleInput
                placeholder='Insira o link'
                id={""+index}
                value={input}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onBlur={() => handleInputBlur(index)}
              />
              {input && ( 
                <button
                  className="absolute inset-y-0 right-0 flex items-center justify-center px-2 text-redError hover:text-customRed"
                  onClick={() => removeInput(index)}
                >
                  <RiCloseCircleFill />
                </button>
              )}
          </motion.div>
        ))}
      </AnimatePresence>
        <div className='
          flex
          flex-row
          items-center
        '
          onClick={addInput}
        >
          <div className='flex-1'>
            <hr className='border-t-2 border-lilas'/>
          </div>
          <div className='text-lilas mx-3'>
            <CiCirclePlus size={20}/>
          </div>
          <div className='flex-1 bg-lilas'>
            <hr className='border-t-2 border-lilas'/>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default DynamicInputs;