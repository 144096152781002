import React from 'react';
import useConfirmationModal from '../../hooks/useConfirmationModal';
import Button from '../Button';

interface IConfimationDialog{
    message: string;
}


const ConfirmationDialog: React.FC<IConfimationDialog> = ({
    message
}) => {
  const { isOpen, onClose, onConfirm, onRefuse } = useConfirmationModal(state => ({
    isOpen: state.isOpen,
    onClose: state.onClose,
    onConfirm: state.onConfirm,
    onRefuse: state.onRefuse,
  }));

  if (!isOpen) return null;

  return (
    <div className='
        justify-center
        items-center
        flex
        overflow-x-hidden
        overflow-y-auto
        fixed
        inset-0
        z-50
        outline-none
        focus:outline-none
    '>
      <div className='bg-blueGray p-14 rounded-lg text-center flex flex-col items-center gap-20'>
        <p>{message}</p>
        <section className='flex flex-row gap-10 w-full'>
            
            <Button
                label='Não'
                onClick={() => {
                    onRefuse();
                    onClose();
                }}
                small
            />

            <Button
                label='Sim'
                onClick={() => {
                    onConfirm();
                    onClose();
                }}
                small
            />
            
        </section>
      </div>
    </div>
  );
};

// const styles = {

//   dialog: {
    // backgroundColor: 'white',
    // padding: '20px',
    // borderRadius: '8px',
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    // textAlign: 'center',
//   },
// };

export default ConfirmationDialog;