import React,{useState} from "react";
import PortifolioSection from "../PortifolioSection";
import CustomSizePicture from "../../photo/CustomSizePicture";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules'
import 'swiper/css/scrollbar';
import "swiper/css";
import YoutubeIframe from "./YoutubeIframe";
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronLeft ,FaChevronRight } from "react-icons/fa";
import MenuPagination from "./MenuPagination";



interface PortifolioGaleryProps{
    items:  string[]
}

const PortifolioVideos: React.FC<PortifolioGaleryProps> = ({
    items
}) =>{
    const itemsPerPage = 1;
    const [currentPage, setCurrentPage] = useState(1);


    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedItems = items.slice(startIndex, startIndex + itemsPerPage);


    const body = (
        <div className="flex flex-col w-full">
            <AnimatePresence>
                {paginatedItems.map((item, index) => (
                    <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -400 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 400 }}
                    transition={{ duration: 1 }}
                  >
                        <YoutubeIframe key={startIndex + index} youtubeUrl={item} />
                    </motion.div>
                ))}
            </AnimatePresence>
        </div>
    )

    const totalPages = Math.ceil(items.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const handlePreviousPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };

    const buttons = (
        <MenuPagination
            currentPage={currentPage}
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
            totalPages={totalPages}
        />
    )


    return(
        <>
            <PortifolioSection
                title="Videos"
                body={body}
                buttons={buttons}
            />
       </>
    )
}

export default PortifolioVideos