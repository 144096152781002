import React from "react";

interface ContainerProps {
    children : React.ReactNode
    green?: boolean
}

const SmallContainer: React.FC<ContainerProps> = ({
    children,
    green=false,
}) =>{
    return(
        <div
           className={`
            flex
            flex-row 
            items-center 
            justify-center 
            w-1/2 
            ${green? 'bg-verde' : 'bg-lilas' }
            py-4
            `}
        >{children}</div>
    )
}

export default SmallContainer