
import React from "react";

import Container from "../../components/containers/Container";
import PortifolioHead from "../../components/portifolio/PortifolioClient/PortifolioHead"; 
import PortifolioInfo from "../../components/portifolio/PortifolioClient/PortifolioInfo";
import PortifolioContact from "../../components/portifolio/PortifolioClient/PortifolioContact";
import PortifolioIncluded from "../../components/portifolio/PortifolioClient/PortifolioIncluded";
import PortifolioGalery from "../../components/portifolio/PortifolioClient/PortifolioGalery";
import PortifolioVideos from "../../components/portifolio/PortifolioClient/PortifolioVideos";
import Project from "../../models/Project";
import PortifolioItemCard from "../../components/portifolio/PortifolioClient/PortifolioItemCard";
import PortifolioMusics from "../../components/portifolio/PortifolioClient/PortifolioMusics";
import PortifolioDownloads from "../../components/portifolio/PortifolioClient/PortifolioDownloads";
import PortifolioFaq from "../../components/portifolio/PortifolioClient/PortifolioFaq";



const PATH = process.env.REACT_APP_MINIO_URL


interface projectClientProps {    
    project: Project,
}

const PortifolioClient: React.FC<projectClientProps> = ({
  project,
}) => {
  const creationYear = new Date(project.createAt).getFullYear();
  const updatedAt = new Date(project.updateAt);
  const monthName = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(updatedAt);
  const updateDate = monthName + " de " + updatedAt.getFullYear();
  const musicStyles = [
    project.musicStyle.musicStyle1,
    project.musicStyle.musicStyle2,
    project.musicStyle.musicStyle3,
    project.musicStyle.musicStyle4
  ].filter(Boolean);

  const includedIn = project.includedIn?.filter(e => e.item !== null)
  const artists = project.artists?.filter(e=>(e.name && e.photo && e.photo !== undefined))
  const apresentedIn = project.apresentedIn?.filter(e=>(e.name && e.photo && e.photo !== undefined))
  const FAQ = project.faq?.filter(e=> (e.item && e.content))
  const profilePic =  project.media && project.media.profilePic ? project.media.profilePic[0]  : undefined;


  const musicStyleTypes = musicStyles.join(', ');
  const coverImage = project.media && project.media.coverImage && project.media.coverImage.length > 0 &&
   project.media.coverImage.length !== undefined ? PATH + project.media.coverImage[0] : "/images/coverplaceholder.jpg"

  
  return (    
    <>
      <PortifolioHead
        imageSrc={coverImage}
      />

      <Container>
        <div 
          className="
            relative
            max-w-screen-lg 
            mx-auto
            grid 
            grid-cols-1 
            md:grid-cols-12 
            relative
            
          "
        >
          <div className="flex flex-col col-span-8 relative mt-[-19%] gap-6">

            <div 
              className="
                md:gap-10 
                mt-6
              "
            >
              <PortifolioInfo
                creationDate = {creationYear}
                updateDate = {updateDate}
                description={project.description || ""}
                city={project.localization.city}
                country={project.localization.country}
                state={project.localization.state}
                name={project.name}
                musicStyle={musicStyleTypes}
                profilePic={profilePic}
              />
              
            </div>
            {artists && artists.length > 0  &&(
              <PortifolioItemCard 
                items={artists}
                title="Integrantes"
              />
            )}

            {project.media && project.media.youtubeLinks && project.media.youtubeLinks.length > 0 && 
              <PortifolioVideos            
                items={project.media.youtubeLinks}
              />
            }
            
            {project.media && project.media.showPictures && project.media.showPictures.length  > 0 && 
              (<PortifolioGalery            
                items={project.media.showPictures}
              />)
            }
            
            {(project.musicLinks && (project.musicLinks.spotify || project.musicLinks.soundCloud || project.musicLinks.deezer)) &&(
              <PortifolioMusics 
                musicLinks={project.musicLinks}                
              />)         
            }
            
            {apresentedIn && apresentedIn.length > 0 &&(
              <PortifolioItemCard 
                items={apresentedIn}
                title="Locais onde já se apresentou"
              />
            )}

            {includedIn && includedIn.length > 0 &&
              <PortifolioIncluded items={includedIn}/>
            }

            {(project.promoMaterial && (project.promoMaterial.flyer || project.promoMaterial.logo
             || project.promoMaterial.preskit || project.promoMaterial.video)) && 
              <PortifolioDownloads promoMaterial={project.promoMaterial}/>
            }
            {FAQ && FAQ.length > 0 &&
              <PortifolioFaq faq={FAQ}/>
            }

          </div>
          <div 
                className="
                  mb-10 
                  md:order-last 
                  md:col-span-4
                "
              >
                <PortifolioContact
                  identifier={project.name}
                  land_distance={project.logisticDetail.landDistance}
                  maxPrice={project.logisticDetail.maxPrice}
                  minPrice={project.logisticDetail.minPrice}
                  phone={project.logisticDetail.phone.replace(/\D/g, "")}
                  team={project.logisticDetail.team}
                />
          </div>

        </div>
      </Container>
    </>
   );

}

export default PortifolioClient;